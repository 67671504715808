import {HttpErrorResponse, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngxs/store';
import {throwError} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';

import {StatusAcountEnum} from '@savvy/enum/statusAccountEnum';
import {StepAccountEnum} from '@savvy/enum/stepAccountEnum';
import {FeatureFlag} from '@savvy/models/flags.model';
import {RemoteConfigService} from '@savvy/services/remote-config.service';
import {Auth} from '@savvy/store/actions/auth.actions';
import {AuthState} from '@savvy/store/state/auth.state';

import {BypassService} from './bypass.service';
import {GlobalErrorHandler} from './error.handler';

const NOT_REDIRECT = [
	'/auth/create-account',
	'/faq',
	'/agreements/terms',
	'/agreements/policy',
	'/auth/login',
	'/admin',
	'/auth/forgot-password',
];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(
		private store: Store,
		private router: Router,
		private bypassService: BypassService,
		private remoteConfig: RemoteConfigService,
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler) {
		const token = this.store.selectSnapshot(AuthState.token);
		const isApiRequest = !/https?:\/\//.test(request.url);
		const headers: Record<string, string> = {};
		if (isApiRequest) {
			if (token) {
				headers.authorization = `Bearer ${token}`;
			}

			headers['X-Flags'] = (FeatureFlag.NewPortfolioApi | FeatureFlag.ScheduledOrders).toString();
		}
		return next
			.handle(
				request.clone({
					setHeaders: headers,
					url: isApiRequest ? this.bypassService.get('serverBaseUrl') + request.url : request.url,
				}),
			)
			.pipe(
				tap((event) => {
					switch (event.type) {
						case HttpEventType.ResponseHeader:
							GlobalErrorHandler.log(
								'HTTP',
								'Response Header',
								event.url,
								`${event.status} ${event.statusText}`,
							);
							break;
						case HttpEventType.Response:
							if (!NOT_REDIRECT.includes(this.router.url.replace(/\?.*$/, ''))) {
								const auth = JSON.parse(localStorage.getItem('auth'));

								if (
									auth.step !== StepAccountEnum.FINISHED ||
									(auth.accountStatus !== StatusAcountEnum.Aprovado &&
										auth.accountStatus !== StatusAcountEnum.AprovadoAuto)
								) {
									localStorage.removeItem('auth');
									this.router.navigateByUrl('/auth/login');
								}
							}
							GlobalErrorHandler.log(
								'HTTP',
								'Response Header',
								event.url,
								`${event.status} ${event.statusText}`,
							);
							GlobalErrorHandler.log('HTTP', 'Response Body', event.url, event.body);
							break;
					}
				}),
				catchError((err) => {
					if (err instanceof HttpErrorResponse && err.status === 401) {
						if (err.status === 401) {
							this.store.dispatch(new Auth.Logout());
							if (!NOT_REDIRECT.includes(this.router.url.replace(/\?.*$/, ''))) {
								void this.router.navigateByUrl('/auth/login');
							}
						}

						const key = err.error.message || err.message || err;

						return this.remoteConfig.errorMessages$.pipe(
							map((messages) => messages[key]),
							// eslint-disable-next-line @typescript-eslint/dot-notation
							tap((message) => (err['parsed'] = message?.en_US)),
							switchMap(() => throwError(() => err)),
						);
					}
					return throwError(() => err);
				}),
			);
	}
}
