import {ComponentPortal, DomPortalOutlet} from '@angular/cdk/portal';
import {ApplicationRef, ComponentFactoryResolver, Injectable, Injector} from '@angular/core';
import {first} from 'rxjs/operators';

import {NotificationsComponent} from '@savvy/components/notifications/notifications.component';

@Injectable({
	providedIn: 'root',
})
export class NotificationsPortalService {
	static portalHost: DomPortalOutlet;
	public component: NotificationsComponent;

	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private applicationRef: ApplicationRef,
		private injector: Injector,
	) {
		if (!NotificationsPortalService.portalHost) {
			NotificationsPortalService.portalHost = new DomPortalOutlet(
				document.body,
				this.componentFactoryResolver,
				this.applicationRef,
				this.injector,
			);
		}
	}

	toggle() {
		if (NotificationsPortalService.portalHost.hasAttached()) {
			this.close();
		} else {
			this.open();
		}
	}

	open() {
		const portal = new ComponentPortal(NotificationsComponent);
		const componentRef = NotificationsPortalService.portalHost.attach(portal);
		componentRef.instance.closeClick.pipe(first()).subscribe(() => this.close());
		this.component = componentRef.instance;
	}

	close() {
		NotificationsPortalService.portalHost.detach();
		this.component = null;
	}
}
