import { OverlayModule } from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

import {TooltipModule} from '@savvy/directives/tooltip/tooltip.module';
import {QrTooltipComponent} from './qr-tooltip.component';

@NgModule({
	declarations: [QrTooltipComponent],
	imports: [CommonModule, MatIconModule, MatButtonModule, TooltipModule, OverlayModule],
})
export class QrTooltipModule {}
