import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Categories, Instrument, InstrumentDetails, Statements} from '@savvy/models/store';
import {StatementType} from '@savvy/views/account-statements/account-statements.component';

@Injectable({providedIn: 'root'})
export class PortfolioService {
	constructor(private http: HttpClient) {}

	getInstruments() {
		return this.http.get<Instrument[]>('/api/securities/instruments');
	}

	getCategories() {
		return this.http.get<Categories[]>('/api/securities/categories');
	}

	getInstrumentDetails(instrumentId: string) {
		return this.http.get<InstrumentDetails>(`/api/securities/instruments/${instrumentId}`);
	}

	cancelOrder(orderId: string) {
		return this.http.delete<void>(`/api/orders/${orderId}`);
	}

	getStatements(type: StatementType) {
		const to = new Date().toISOString();
		return this.http.get<Statements>(`/api/statements?type=${type}&from=1900-01-01T00:00:00Z&to=${to}`);
	}

	getStatement(fileKey: string) {
		return this.http.get<{url: string}>(`/api/statements/${fileKey}`);
	}
}
