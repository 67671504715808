import {Injectable} from '@angular/core';
import {Action, createSelector, Selector, State, StateContext} from '@ngxs/store';
import {from} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';

import {handleStoreError} from '@savvy/helpers/handleStoreError';
import {Subscription} from '@savvy/models/store';
import {SubscriptionService} from '@savvy/services/subscription.service';
import {Subscriptions} from '@savvy/store/actions/subscription.actions';
import {ErrorState} from '@savvy/store/state/error.state';

export interface SubscriptionStateModel {
	subscriptions: Subscription[];
	error: string | null;
}

@State({
	name: 'subscription',
	defaults: {
		subscriptions: [],
		error: null,
	},
})
@Injectable()
export class SubscriptionState extends ErrorState {
	constructor(private subscriptionService: SubscriptionService) {
		super();
	}

	static subscriptionByLink(link: string) {
		return createSelector([SubscriptionState.subscriptions], (subscriptions: Subscription[]) =>
			subscriptions.find((item) => item.link === link),
		);
	}

	@Selector([SubscriptionState])
	static subscriptions(state: SubscriptionStateModel) {
		return state.subscriptions;
	}

	@Action(Subscriptions.Load)
	loadSubscriptions(ctx: StateContext<SubscriptionStateModel>) {
		return this.subscriptionService.load().pipe(
			tap((subscriptions) => ctx.patchState({subscriptions})),
			handleStoreError(ctx),
		);
	}

	@Action(Subscriptions.Create)
	createSubscription(ctx: StateContext<SubscriptionStateModel>, action: Subscriptions.Create) {
		return this.subscriptionService
			.create({
				paymentMethodId: action.request.paymentMethodId,
				priceId: action.request.priceId,
			})
			.pipe(
				map((res) => {
					if (res.error) {
						throw res;
					}
					return res;
				}),
				map((res) => ({
					paymentMethodId: action.request.paymentMethodId,
					subscription: res,
				})),
				switchMap((res) => from(this.subscriptionService.onCustomerActionRequired(res))),
				map((res) => this.subscriptionService.onPaymentMethodRequired(res)),
				tap(() => {
					localStorage.removeItem('stripe__latestInvoiceId');
					localStorage.removeItem('stripe__latestInvoicePaymentIntentStatus');
				}),
				handleStoreError(ctx),
			);
	}

	@Action(Subscriptions.Retry)
	retrySubscription(ctx: StateContext<SubscriptionStateModel>, action: Subscriptions.Retry) {
		return this.subscriptionService
			.retry({
				paymentMethodId: action.request.paymentMethodId,
				invoiceId: action.request.invoiceId,
			})
			.pipe(
				map((res) => {
					if (res.error) {
						throw res;
					}
					return res;
				}),
				map((res) => ({
					paymentMethodId: action.request.paymentMethodId,
					invoice: res,
					isRetry: true,
				})),
				switchMap((res) => from(this.subscriptionService.onCustomerActionRequired(res))),
				tap(() => {
					localStorage.removeItem('stripe__latestInvoiceId');
					localStorage.removeItem('stripe__latestInvoicePaymentIntentStatus');
				}),
				handleStoreError(ctx),
			);
	}

	@Action(Subscriptions.Delete)
	deleteSubscription(ctx: StateContext<SubscriptionStateModel>) {
		return this.subscriptionService.delete().pipe(handleStoreError(ctx));
	}
}
