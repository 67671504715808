import {formatCurrency, formatDate} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {filterNull} from '@savvy/helpers/filterNull';
import {
	AdditionalInformationStep,
	DisclosuresStep,
	InvestmentProfileStep,
	OnboardingData,
	OnboardingOptionsType,
	OnboardingStep,
	PhysicalDocumentType,
	ProfileAdditionalInformation,
	ProfileDisclosures,
	ProfileInvestment,
	ProfilePersonalInformation,
} from '@savvy/models/onboarding.model';
import {OnboardingOptions} from '@savvy/models/remoteConfig.model';
import {UserDetails} from '@savvy/models/store';

import {RemoteConfigService} from './remote-config.service';

const numberToPriceRange = (arr: [number, number][], locale: string) =>
	arr
		.map((values) => ({
			labels: values.map((value) => value && formatCurrency(value, locale, 'US$', '1.0-0')),
			values,
		}))
		.map(({values, labels}) => ({
			label: labels[1] ? `${labels[0]}-${labels[1]}` : `${labels[0]}+`,
			value: values[1] || values[0],
		}));

@Injectable({
	providedIn: 'root',
})
export class OnboardingService {
	private readonly configFallback = {
		[OnboardingOptionsType.AnnualIncome]: numberToPriceRange(
			[
				[0, 24_999],
				[25_000, 49_999],
				[50_000, 99_999],
				[100_000, 199_999],
				[200_000, null],
			],
			this.locale,
		),
		[OnboardingOptionsType.NetIncome]: numberToPriceRange(
			[
				[0, 4_999],
				[5_000, 24_999],
				[25_000, 49_999],
				[50_000, 99_999],
				[100_000, 199_999],
				[200_000, 499_999],
				[500_000, null],
			],
			this.locale,
		),
	};

	constructor(
		private http: HttpClient,
		@Inject(LOCALE_ID) private locale: string,
		private remoteConfig: RemoteConfigService,
	) {}

	getOnboardingOptions(type: OnboardingOptionsType): Observable<OnboardingOptions> {
		return this.configFallback[type] ? of(this.configFallback[type]) : this.remoteConfig.getOnboardingOptions(type);
	}

	getAllowedCitizenships() {
		return this.remoteConfig.onboardingCitizenshipCountries$.pipe(
			map((countries) => countries.filter((item) => item.active)),
		);
	}

	resetOnboarding() {
		return this.http.get('/api/helpers/resetOnboarding');
	}

	uploadPhysicalDocument<T extends PhysicalDocumentType>(
		type: T,
		files: T extends PhysicalDocumentType.Identification ? [File, File] : [File],
		isUpdating: boolean,
		bypassValidation = false,
	) {
		const formData = new FormData();

		if (type === PhysicalDocumentType.Identification) {
			formData.append('front', files[0]);
			formData.append('back', files[1]);
		} else {
			formData.append('document', files[0]);
		}

		return this.http[isUpdating ? 'patch' : 'post'](`/api/user/${type}`, formData, {
			params: bypassValidation
				? {
						skipForged: 'true',
				  }
				: {},
			responseType: 'text',
		});
	}

	setApproved(document: PhysicalDocumentType, isUpdated = false, approved = true) {
		return this.http[isUpdated ? 'patch' : 'put']<void>('/api/user/approve', {document, approved});
	}

	uploadUserDetails(
		step: OnboardingStep,
		isUpdating: boolean,
		data: AdditionalInformationStep | InvestmentProfileStep | DisclosuresStep,
	): Observable<void> {
		let body = null as Record<string, any>;
		switch (step) {
			case OnboardingStep.AdditionalInformation:
				data = data as AdditionalInformationStep;
				body = {
					identificationData: {
						citizenship: data.citizenship,
					},
					personalData: {
						marital: data.maritalStatus,
						partnerName: data.maritalName,
					},
					employmentData: {
						status: data.employedStatus,
						company: data.employedName,
						type: data.employedType,
						position: data.employedPosition,
					},
				};
				if (isUpdating) {
					delete body.identificationData;
				}
				break;
			case OnboardingStep.InvestmentProfile:
				data = data as InvestmentProfileStep;
				body = {
					investmentData: {
						investmentExperience: data.investingExperience,
						annualIncome: data.annualIncome,
						networthTotal: data.netIncome,
						riskTolerance: data.riskTolerance,
						importanceOfLiquidity: data.importanceOfLiquidity,
						investmentHorizon: data.investmentHorizon,
					},
				};
				break;
			case OnboardingStep.Disclosures:
				data = data as DisclosuresStep;
				body = {
					investmentData: {
						officialsName: data.publicOfficialName,
						officialsMembers: data.publicOfficialMembers,
						affiliatedCompanyName: data.affiliatedCompanyName,
						shareholderOrDirectorCompanyName: data.shareholderCompanyName,
						shareholderOrDirectorCompanySymbol: data.shareholderTickerSymbol,
						startDateOfOfficialsPosition:
							data.startDateOfOfficialsPosition &&
							formatDate(data.startDateOfOfficialsPosition, 'yyyy-MM-dd', this.locale),
					},
				};
				break;
		}
		return this.http[isUpdating ? 'patch' : 'put']<void>('/api/user', filterNull(body));
	}

	mapDetailsToOnboarding(details: UserDetails): OnboardingData {
		return {
			addressData: {
				...details.addressData,
			},
			documents: null,
			employmentData: details.employmentData,
			identificationData: {
				...details.identificationData,
				citizenship: 'BRA',
			},
			investmentData: details.investmentData,
			personalData: details.personalData,
		};
	}

	uploadProfileDetails(
		step: OnboardingStep,
		data: ProfilePersonalInformation | ProfileAdditionalInformation | ProfileInvestment | ProfileDisclosures,
	): Observable<any> {
		let type = '';

		switch (step) {
			case OnboardingStep.PersonalInformation:
				type = '/persolnalInfo';
				break;
			case OnboardingStep.InvestmentProfile:
				type = '/investmentProfile';
				break;
			case OnboardingStep.Disclosures:
				type = '/disclosures';
				break;
			case OnboardingStep.AdditionalInformation:
				type = '/persolnalAddress';
				break;
		}

		return this.http.post<any>(`/api/user${type}`, data);
	}
}
