import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {Subscription} from 'rxjs';
import {retry} from 'rxjs/operators';
import {webSocket} from 'rxjs/webSocket';

import {ReplyStatus, SocketClientEvent, SocketEventType, SocketServerEvent} from '@savvy/models/store/stream';

import {Stream} from '@savvy/store/actions/stream.actions';
import {AuthState} from '@savvy/store/state/auth.state';
import {BypassService} from './bypass.service';

@Injectable({providedIn: 'root'})
export class StreamService {
	private readonly subject = webSocket<SocketClientEvent | SocketServerEvent>({
		url: this.bypassService.get('wsBaseUrl'),
		openObserver: {
			next: () => {
				const token = this.store.selectSnapshot(AuthState.token);
				if (token) {
					this.store.dispatch(new Stream.Auth(token));
				}
			},
		},
	});
	private readonly subscriptionEventPairs = [
		[SocketEventType.ChartSubscribe, SocketEventType.ChartUnsubscribe],
		[SocketEventType.FxSubscribe, SocketEventType.FxUnsubscribe],
		[SocketEventType.PriceSubscribe, SocketEventType.PriceUnsubscribe],
		[SocketEventType.MarketStatusSubscribe, SocketEventType.MarketStatusUnsubscribe],
	];
	private readonly eventSubscribers: Record<string, number> = {};
	private subscription: Subscription | null = null;
	private isLoggedIn = false;
	private pendingEvents: SocketClientEvent[] = [];

	constructor(private store: Store, private bypassService: BypassService) {
		console.log('[StreamService] Connecting');
		this.subscription = this.subject.pipe(retry()).subscribe({
			next: (message) => {
				console.log(`[StreamService] Receiving event ${message.e}`);
				if (message.e === SocketEventType.ReplyStatus && message.p.responseFor.e === SocketEventType.Auth) {
					this.isLoggedIn = message.p.status === ReplyStatus.Success;
					if (this.isLoggedIn) {
						this.pendingEvents.forEach((event) => this.subject.next(event));
						this.pendingEvents.length = 0;
					}
				}
				this.store.dispatch(new Stream.SaveEvent(message as SocketServerEvent));
			},
			error: (error) => {
				console.error('[StreamService] Error', error);
				this.isLoggedIn = false;
			},
		});
	}

	private static buildEventKey(event: SocketClientEvent) {
		switch (event.e) {
			case SocketEventType.FxSubscribe:
			case SocketEventType.FxUnsubscribe:
				return 'fx';
			case SocketEventType.MarketStatusSubscribe:
			case SocketEventType.MarketStatusUnsubscribe:
				return 'market';
			case SocketEventType.PriceSubscribe:
			case SocketEventType.PriceUnsubscribe:
				return `price-${event.p.type}-${event.p.id}`;
			case SocketEventType.ChartSubscribe:
			case SocketEventType.ChartUnsubscribe:
				return `chart-${event.p.type}-${event.p.id}-${event.p.period}`;
			default:
				return null;
		}
	}

	sendEvent(event: SocketClientEvent) {
		console.log(`[StreamService] Sending event ${event.e}`);
		const eventKey = StreamService.buildEventKey(event);
		if (eventKey) {
			const [subscriptionKey] = this.getSubscriptionEvent(event.e);
			if (event.e === subscriptionKey) {
				this.eventSubscribers[eventKey] = (this.eventSubscribers[eventKey] || 0) + 1;
				if (this.eventSubscribers[eventKey] > 1) {
					return;
				}
			} else if (--this.eventSubscribers[eventKey]) {
				return;
			}
		}
		this.prepareEvent(event);
	}

	private prepareEvent(event: SocketClientEvent) {
		if (event.e !== SocketEventType.Auth && !this.isLoggedIn) {
			this.pendingEvents.push(event);
			return;
		}

		this.subject.next(event);
	}

	private getSubscriptionEvent(event: SocketEventType) {
		return this.subscriptionEventPairs.find(([key, value]) => key === event || value === event);
	}
}
