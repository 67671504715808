import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateConvert',
})
export class DatePipe implements PipeTransform {
    transform(value: number): string {

        let data = value.toString();
        let result = data.slice(6, 8).concat("/").concat(data.slice(4, 6).concat("/")).concat(data.slice(0, 4));
        
        return result;
    }
}
