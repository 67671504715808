import {Injectable} from '@angular/core';
import {Action, getActionTypeFromInstance, Selector, State, StateContext} from '@ngxs/store';
import {AccountStatus, ActivityEntry, Notification, PortfolioNotification} from '@savvy/models/store';
import {FirebaseService, FirebaseSubscriptionType} from '@savvy/services/firebase.service';
import {Firebase} from '@savvy/store/actions/firebase.actions';

export interface FirebaseStateModel {
	activities: ActivityEntry[];
	notifications: Notification[];
	portfolioNotifications: PortfolioNotification[];
	user: {
		status: {
			savvy: AccountStatus;
			dw: AccountStatus;
			topazio: AccountStatus;
		};
		unread: number;
	};
	subscriptions: Record<FirebaseSubscriptionType, number>;
}

const getInitialState = (): FirebaseStateModel => ({
	activities: [],
	notifications: [],
	portfolioNotifications: [],
	user: {
		status: {
			savvy: null,
			dw: null,
			topazio: null,
		},
		unread: 0,
	},
	subscriptions: Object.fromEntries(Object.values(FirebaseSubscriptionType).map((key) => [key, 0])) as Record<
		FirebaseSubscriptionType,
		number
	>,
});

@State<FirebaseStateModel>({
	name: 'firebase',
	defaults: getInitialState(),
})
@Injectable()
export class FirebaseState {
	constructor(private firebaseService: FirebaseService) {}

	@Selector([FirebaseState])
	static unread(state: FirebaseStateModel) {
		return state.user.unread;
	}

	@Selector([FirebaseState])
	static activity(state: FirebaseStateModel) {
		return state.activities;
	}

	@Selector([FirebaseState])
	static userStatus(state: FirebaseStateModel) {
		return state.user.status.savvy;
	}

	@Selector([FirebaseState])
	static notifications(state: FirebaseStateModel) {
		return state.notifications;
	}

	@Selector([FirebaseState])
	static portfolioNotifications(state: FirebaseStateModel) {
		return state.portfolioNotifications;
	}

	@Action(Firebase.Activity.Subscribe)
	@Action(Firebase.Notification.Subscribe)
	@Action(Firebase.PortfolioNotification.Subscribe)
	@Action(Firebase.User.Subscribe)
	subscribeTo(
		ctx: StateContext<FirebaseStateModel>,
		action: Firebase.Activity.Subscribe | Firebase.Notification.Subscribe | Firebase.User.Subscribe,
	) {
		this.handleSubscribe(
			ctx,
			{
				[Firebase.User.Subscribe.type]: FirebaseSubscriptionType.User,
				[Firebase.Notification.Subscribe.type]: FirebaseSubscriptionType.Notifications,
				[Firebase.PortfolioNotification.Subscribe.type]: FirebaseSubscriptionType.PortfolioNotifications,
				[Firebase.Activity.Subscribe.type]: FirebaseSubscriptionType.Activities,
			}[getActionTypeFromInstance(action)],
		);
	}

	@Action(Firebase.Activity.Unsubscribe)
	@Action(Firebase.Notification.Unsubscribe)
	@Action(Firebase.PortfolioNotification.Unsubscribe)
	@Action(Firebase.User.Unsubscribe)
	unsubscribeFrom(
		ctx: StateContext<FirebaseStateModel>,
		action: Firebase.Activity.Unsubscribe | Firebase.Notification.Unsubscribe | Firebase.User.Unsubscribe,
	) {
		this.handleUnsubscribe(
			ctx,
			{
				[Firebase.User.Unsubscribe.type]: FirebaseSubscriptionType.User,
				[Firebase.Notification.Unsubscribe.type]: FirebaseSubscriptionType.Notifications,
				[Firebase.PortfolioNotification.Unsubscribe.type]: FirebaseSubscriptionType.PortfolioNotifications,
				[Firebase.Activity.Unsubscribe.type]: FirebaseSubscriptionType.Activities,
			}[getActionTypeFromInstance(action)],
		);
	}

	@Action(Firebase.Activity.Received)
	receivedActivity(ctx: StateContext<FirebaseStateModel>, action: Firebase.Activity.Received) {
		ctx.patchState({
			activities: action.entries,
		});
	}

	@Action(Firebase.Notification.Received)
	receivedNotifications(ctx: StateContext<FirebaseStateModel>, action: Firebase.Notification.Received) {
		ctx.patchState({
			notifications: action.entries,
		});
	}

	@Action(Firebase.PortfolioNotification.Received)
	receivedPortfolioNotifications(
		ctx: StateContext<FirebaseStateModel>,
		action: Firebase.PortfolioNotification.Received,
	) {
		ctx.patchState({
			portfolioNotifications: action.entries,
		});
	}

	@Action(Firebase.User.Received)
	receivedUser(ctx: StateContext<FirebaseStateModel>, action: Firebase.User.Received) {
		ctx.patchState({
			user: {
				unread: action.user.unread,
				status: {
					savvy: action.user.status,
					dw: action.user.dwStatus,
					topazio: action.user.topazioStatus,
				},
			},
		});
	}

	@Action(Firebase.Notification.Delete)
	deleteNotification(ctx: StateContext<FirebaseStateModel>, action: Firebase.Notification.Delete) {
		return this.firebaseService.deleteNotification(action.id);
	}

	@Action(Firebase.Notification.MarkAsRead)
	markNotificationAsRead(ctx: StateContext<FirebaseStateModel>, action: Firebase.Notification.Delete) {
		return this.firebaseService.markNotificationAsRead(action.id);
	}

	@Action(Firebase.User.Logout)
	logout(ctx: StateContext<FirebaseStateModel>) {
		ctx.setState(getInitialState());
	}

	private handleSubscribe(ctx: StateContext<FirebaseStateModel>, key: FirebaseSubscriptionType) {
		const state = ctx.getState();
		if (state.subscriptions[key] === 0) {
			this.firebaseService.listenTo(key);
		}
		ctx.patchState({
			subscriptions: {
				...state.subscriptions,
				[key]: state.subscriptions[key] + 1,
			},
		});
	}

	private handleUnsubscribe(ctx: StateContext<FirebaseStateModel>, key: FirebaseSubscriptionType) {
		const state = ctx.getState();
		if (state.subscriptions[key] <= 1) {
			this.firebaseService.removeListener(key);
		}
		ctx.patchState({
			subscriptions: {
				...state.subscriptions,
				[key]: Math.min(state.subscriptions[key] - 1, 0),
			},
		});
	}
}
