import {
	ActivityEntry,
	FbUser,
	Notification as NotificationItem,
	PortfolioNotification as PortfolioNotificationItem,
} from '@savvy/models/store';

export namespace Firebase {
	export namespace Activity {
		export class Subscribe {
			static readonly type = '[Firebase] Subscribe To Activities';
		}

		export class Unsubscribe {
			static readonly type = '[Firebase] Unsubscribe From Activities';
		}

		export class Received {
			static readonly type = '[Firebase] Activities Received';

			constructor(public entries: ActivityEntry[]) {}
		}
	}

	export namespace Notification {
		export class Subscribe {
			static readonly type = '[Firebase] Subscribe To Notifications';
		}

		export class Unsubscribe {
			static readonly type = '[Firebase] Unsubscribe From Notifications';
		}

		export class Received {
			static readonly type = '[Firebase] Notifications Received';

			constructor(public entries: NotificationItem[]) {}
		}

		export class Delete {
			static readonly type = '[Firebase] Delete Notification';

			constructor(public id: string) {}
		}

		export class MarkAsRead {
			static readonly type = '[Firebase] Mark Notification as Read';

			constructor(public id: string) {}
		}
	}

	export namespace PortfolioNotification {
		export class Subscribe {
			static readonly type = '[Firebase] Subscribe To Portfolio Notifications';
		}

		export class Unsubscribe {
			static readonly type = '[Firebase] Unsubscribe From Portfolio Notifications';
		}

		export class Received {
			static readonly type = '[Firebase] Portfolio Notifications Received';

			constructor(public entries: PortfolioNotificationItem[]) {}
		}
	}

	export namespace User {
		export class Logout {
			static readonly type = '[Auth] Logout';
		}

		export class Subscribe {
			static readonly type = '[Firebase] Subscribe To User';
		}

		export class Unsubscribe {
			static readonly type = '[Firebase] Unsubscribe From User';
		}

		export class Received {
			static readonly type = '[Firebase] User Received';

			constructor(public user: FbUser) {}
		}
	}
}
