import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';

import {environment} from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class UtilService {
	static readonly VERSION_KEY = 's::version';

	public isSmallScreen$ = this.breakpointObserver
		.observe([Breakpoints.Small, Breakpoints.XSmall])
		.pipe(map((observer) => observer.matches));

	constructor(private breakpointObserver: BreakpointObserver) {}

	coldBoot() {
		if (environment.version === localStorage.getItem(UtilService.VERSION_KEY)) {
			return;
		}
		localStorage.clear();
		localStorage.setItem(UtilService.VERSION_KEY, environment.version);
		location.reload();
	}

	/**
	 * Return SUID (url-safe base64 ID)
	 *
	 * @param length Length of ID in bytes
	 */
	suid(length = 6) {
		const bytes = new Uint8Array(length);
		crypto.getRandomValues(bytes);
		const id = btoa(String.fromCharCode(...bytes));
		return id.replace(/\//g, '_').replace(/\+/g, '-');
	}

	parseMedia(data: string) {
		return data
			.replace(
				/<oembed url="https:\/\/open\.spotify\.com\/([^\/\s]+)\/([^\/\s?]+).*"><\/oembed>/,
				'<iframe src="https://open.spotify.com/embed/$1/$2" allowtransparency="true" allow="encrypted-media"></iframe>',
			)
			.replace(
				/<oembed url="https:\/\/vimeo\.com\/(\d+).*"><\/oembed>/,
				'<iframe src="https://player.vimeo.com/video/$1" width="100%" height="100%" allowfullscreen></iframe>',
			);
	}

	buildAssetImageURL(symbol: string) {
		return `https://app.haiservices.com.br/ticker/${symbol}`;
	}
}
