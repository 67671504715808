import {Injectable} from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanActivateChild,
	CanLoad,
	Route,
	Router,
	RouterStateSnapshot,
	UrlSegment,
} from '@angular/router';
import {Store} from '@ngxs/store';
import {AuthState} from '@savvy/store/state/auth.state';

export enum UserType {
	GUEST,
	USER,
}

export interface RouteData {
	role?: UserType[];
	fallbackUrl?: string;
	noFallbackQuery?: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
	constructor(private store: Store, private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.check(route, state);
	}

	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.check(childRoute, state);
	}

	canLoad(route: Route, segments: UrlSegment[]) {
		return this.check(route as ActivatedRouteSnapshot, {url: segments.join('/')});
	}

	private check(route: {data: RouteData}, state: {url: string}): boolean {
		const status = this.store.selectSnapshot(AuthState.isLoggedIn);
		const data = {role: [] as UserType[], fallbackUrl: '/auth/login', ...(route.data || {})};
		if (!data.role.length) {
			return true;
		}
		if (status && data.role.includes(UserType.USER)) {
			return true;
		} else if (!status && data.role.includes(UserType.GUEST)) {
			return true;
		}

		void this.router.navigate([data.fallbackUrl], {queryParams: data.noFallbackQuery ? {} : {r: state.url}});
		return false;
	}
}
