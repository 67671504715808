<section class="fa">

    <div class="fa-title">

        <h1>Você já possui uma conta Haicred </h1>

        <mat-icon [mat-dialog-close]="true" class="material-symbols-outlined">
            <span>close</span>
        </mat-icon>

    </div>

    <div class="fa-btn">
        <mat-divider></mat-divider>
    </div>

    <div class="line">
        <mat-divider></mat-divider>
    </div>

    <div>
        <p>O {{data.duplicateType}} inserido já está cadastrado em uma conta existente da Haicred. Para acessá-la,
            clique no botão
            Entrar.</p>
    </div>

    <div class="fa-btn">

        <button mat-flat-button type="submit" color="primary" routerLink="../auth/login" [mat-dialog-close]="true">
            Entrar
        </button>
    </div>

</section>