export enum PlanName {
	SMA = 'SMA',
	UNDER_30 = 'Under 30',
	E_TRADING = 'E-Trading',
}

export interface Plan {
	amount: number;
	interval: number;
	id: string;
}

export interface Subscription {
	id: string;
	name: PlanName;
	description: string | null;
	price: Plan[];
	image: string;
	link: string;
}

export interface CreateSubscriptionRequestBody {
	paymentMethodId: string;
	priceId?: string;
	invoiceId?: string;
}

export interface CreateSubscriptionResponse {
	id: string;
	status: 'active' | 'canceled' | 'incomplete' | 'incomplete_expired' | 'past_due' | 'trialing' | 'unpaid';
	latest_invoice: {
		id: string;
		status: 'deleted' | 'draft' | 'open' | 'paid' | 'uncollectible' | 'void';
		payment_intent: {
			status:
				| 'canceled'
				| 'processing'
				| 'requires_action'
				| 'requires_capture'
				| 'requires_confirmation'
				| 'requires_payment_method'
				| 'succeeded';
			client_secret: string;
		};
		error: {
			message: string;
		};
	};
	plan: {
		id: string;
		product: string;
	};
	error: {
		message: string;
	};
}
