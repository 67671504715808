import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';

import {pick} from '@savvy/helpers/pick';
import {Bypass} from '@savvy/models/bypass.model';

import {OnboardingService} from '@savvy/services/onboarding.service';

import {environment} from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class BypassService {
	private static readonly storeKey = '@savvy.bypass';
	config = new BehaviorSubject<Bypass>({
		skipDocumentsValidation: false,
		skipPhoneNumberValidation: false,
		disablePortfolioReturns: false,
		uploadSelfieFromFileSystem: false,
		serverBaseUrl: environment.baseurl,
		inviteLink: environment.inviteLink,
		contentGqlBaseUrl: environment.contentGqlBaseurl,
		academyBaseUrl: environment.academyBaseurl,
		faqBaseUrl: environment.faqBaseurl,
		wsBaseUrl: environment.wsBaseUrl,
		showFullVersion: true,
	});

	readonly values = {
		serverBaseUrl: [
			{
				value: 'https://alicorn.haiservices.com.br',
				label: 'Development',
			},
			{
				value: 'https://raccoon.haiservices.com.br',
				label: 'Staging',
			},
			{
				value: 'https://fox.haiservices.com.br',
				label: 'QA',
			},
		],
		wsBaseUrl: [
			{
				value: 'wss://ws-dev.haiservices.com.br/connect',
				label: 'Development',
			},
			{
				value: 'wss://ws.haiservices.com.br/connect',
				label: 'Staging',
			},
			{
				value: 'wss://ws-qa.haiservices.com.br/connect',
				label: 'QA',
			},
		],
	};

	constructor(private onboardingService: OnboardingService) {
		const cached = JSON.parse(localStorage.getItem(BypassService.storeKey) || '{}');
		this.config.next({
			...this.config.value,
			...pick(cached, ...Object.keys(this.config.value)),
		});

		(window as any).__bypass = this;
	}

	public get<T extends keyof Bypass>(key: T): Bypass[T] {
		return this.config.value[key];
	}

	public get$<T extends keyof Bypass>(key: T): Observable<Bypass[T]> {
		return this.config.pipe(
			map((config) => config[key]),
			distinctUntilChanged(),
		);
	}

	public set<T extends keyof Bypass>(key: T, value: Bypass[T]) {
		this.config.next({
			...this.config.value,
			[key]: value,
		});
		this.save();
	}

	public resetOnboarding() {
		this.onboardingService.resetOnboarding().subscribe();
	}

	private save() {
		localStorage.setItem(BypassService.storeKey, JSON.stringify(this.config.value));
	}
}
