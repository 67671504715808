<form [formGroup]="form" (ngSubmit)="onSubmit()">
	<button mat-icon-button mat-dialog-close="" class="close">
		<mat-icon>close</mat-icon>
	</button>
	<h3 mat-dialog-title>Código de Convite</h3>
	<mat-dialog-content>
		<img src="assets/invite-code/invite-email.png" alt="" />
		<p>
			Seu amigo convidou você para se juntar à Família Haicred <br />
			e lhe deu o código de convite
		</p>
		<mat-form-field appearance="outline">
			<input matInput formControlName="code" autocomplete="off" />
		</mat-form-field>
		<mat-error>{{ error$ | async }}</mat-error>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-flat-button type="submit" color="primary">
			<span>Confirmar</span>
		</button>
		<button mat-raised-button mat-dialog-close="">Cancelar</button>
	</mat-dialog-actions>
</form>
