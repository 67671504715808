import {Timestamp} from '@savvy/models/store/notifications';

export interface Transactions {
	requestedCurrency: string;
	id: string;
	latestStatus: number;
	receivedCurrency: string;
	receivedValue: string;
	requestedValue: string;
	type?: number;
	createdAt: Timestamp | Date;
	updatedAt: Timestamp | Date;
}

export interface TransactionDetails {
	id?: string;
	type?: number;
	iof: string;
	iofSpread: string;
	fxRate: string;
	vetFxRate: string;
	fees: string;
	amountBrl: string;
	amountUsd: string;
}
