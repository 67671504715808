import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {map} from 'rxjs/operators';
import {TransactionDetails} from '@savvy/models/store';
import {AccountDetails, BankInformation, Currency, ExchangeRate} from '../models/wallet.model';

@Injectable({
	providedIn: 'root',
})
export class WalletService {
	constructor(private http: HttpClient) {}

	getSubAccountInformation() {
		//todo: should be the sub-account info
		return of({
			recipient: 'Savvy Meios de Pagamento New Field',
			bank: 'Banco Topazio',
			agency: '052',
			accountNumber: '44444-00',
			cnpj: '011.677.000/0001',
		} as BankInformation);
	}

	getAccountDetails() {
		return this.http.get<AccountDetails>('/api/exchange/account');
	}

	getExchangeRate(amount: string, currency: Currency) {
		return this.http.get<ExchangeRate>('/api/exchange/fxRate', {params: {amount, currency}});
	}

	getTransactionsDetails(transaction: TransactionDetails) {
		return this.http
			.get<TransactionDetails>('/api/exchange/details', {params: {id: transaction.id, type: transaction.type}})
			.pipe(map((value) => ({...value, ...transaction})));
	}
}
