import type firebase from 'firebase/compat';

export type Timestamp = firebase.firestore.Timestamp;

export enum NotificationType {
	ACCOUNT_CREATED = 'accounts.created',
	ACCOUNT_UPGRADED = 'accounts.upgraded',
}

export interface Notification {
	id: string;
	content: NotificationContent;
	created: Timestamp | Date;
	read: boolean;
	type: string | NotificationType;
	localised: NotificationLocalised;
}

export enum PortfolioNotificationType {
	RedeemFullGift = 'REDEEM_FULL_GIFT',
	RedeemExtraSlice = 'REDEEM_EXTRA_SLICE',
	Tutorial = 'TUTORIAL',
}

export interface PortfolioNotification {
	read: boolean;
	type: PortfolioNotificationType;
	dismissed: boolean;
}

export interface NotificationContent {
	fileKey?: string;
	subtitle: string;
	text: string;
	title: string;
	replacements: any[];
}

export interface NotificationLocalised {
	en_US: NotificationContent;
	pt_BR: NotificationContent;
}
