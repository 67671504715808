import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'minToDate',
})
export class MinToDatePipe implements PipeTransform {
	transform(value: number): string {
		const minutes = value % 60;
		const hours = (value - minutes) / 60;
		return (hours ? `${hours} h ` : '') + `${Math.round(minutes)} min`;
	}
}
