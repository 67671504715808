import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {map, tap} from 'rxjs/operators';

import {
	ChangePasswordRequest,
	CreateAccountRequest,
	CreateAccountResponse,
	Credentials,
	LoginProvider,
	LoginResponse,
	MFAType,
	SubscribeWaitingListRequest,
	UpdateDisclosuresRequest,
	UpdatePersonalAddressRequest,
	UpdatePersonalInfoRequest,
	User,
	UserDetails,
} from '@savvy/models/store';
import {BypassService} from '@savvy/services/bypass.service';

@Injectable({providedIn: 'root'})
export class AuthService {
	constructor(private http: HttpClient, private auth: AngularFireAuth, private bypassService: BypassService) {
		void auth.setPersistence('local');
	}

	getUser() {
		return this.http.get<User>('/api/user');
	}

	getUserDetails() {
		return this.http.get<UserDetails>('/api/user/details');
	}

	login(body: Credentials, params: {force2FA: true}) {
		return this.http
			.post<LoginResponse>('/api/auth/login', body, {params})
			.pipe(tap((res) => res.firebaseToken && this.auth.signInWithCustomToken(res.firebaseToken)));
	}

	loginWith(type: LoginProvider, token: string) {
		return this.http
			.post<LoginResponse>('/api/auth/loginWith', {type, token})
			.pipe(tap((res) => res.firebaseToken && this.auth.signInWithCustomToken(res.firebaseToken)));
	}

	signupWith(type: LoginProvider, token: string) {
		return this.http.post<{signUpToken: string}>('/api/auth/signupWith', {type, token});
	}

	checkEmail(email: string, sendEmail: boolean = false) {
		return this.http.post(`/api/auth/verifyEmail?sendEmail=${sendEmail}`, {email});
	}

	checkEmailChange(email: string){
		return this.http.post(`/api/auth/emailChangeVerification`, {email});
	}

	checkCPF(cpf: string) {
		return this.http.post('/api/auth/verifyCpf', {cpf});
	}

	checkVerificationCode(code: string, type: string, identification: string) {
		return this.http.post<{token: string}>('/api/auth/verifyCodeWeb', {code, type, identification});
	}

	checkInviteCode(code: string) {
		return this.http.post<void>('/api/user/checkInviteCode', {inviteCode: code});
	}

	checkPhone(phone: string, skip: boolean = true) {
		return this.http.post<{token: string}>('/api/auth/verifyPhone', {phone}, {params: {skip}});
	}

	checkPhoneChange(phone: string){
		return this.http.post(`/api/auth/phoneChangeVerification`, {phone});
	}

	createAccount(form: CreateAccountRequest) {
		return this.http.post<CreateAccountResponse>('/api/user', form);
	}

	updatePersonalInfo(form: UpdatePersonalInfoRequest) {
		return this.http.post<void>('/api/user/persolnalInfo', form);
	}

	updatePersonalAddress(form: UpdatePersonalAddressRequest) {
		return this.http.post<void>('/api/user/persolnalAddress', form);
	}

	sendEmailCode(email: string) {
		return this.http.post<{request: string}>('/api/auth/forgotPassword', {email});
	}

	sendCode(code: string, request: string) {
		return this.http.post<{token: string}>('/api/auth/exchangeResetCodeForToken', {code, request});
	}

	resetPassword(token: string, newPassword: string) {
		return this.http.post('/api/auth/resetPassword', {token, newPassword});
	}

	subscribeToWaitingList(form: SubscribeWaitingListRequest) {
		return this.http.post('/api/waitinglist/subscribe', form);
	}

	changePassword(request: ChangePasswordRequest){
		return this.http.post('/api/auth/changePassword', request);
	}

	delete2Fa() {
		return this.http.delete('/api/auth/remove2FA');
	}

	create2FACode(password: string) {
		return this.http.post(
			'/api/auth/create2FACode',
			{
				password,
				type: MFAType.APP,
			},
			{
				responseType: 'blob',
				headers: {
					Accept: 'image/png',
				},
			},
		);
	}

	getQRLink(password: string, type: MFAType) {
		return this.http
			.post<{url: string}>(
				'/api/auth/create2FACode',
				{
					password,
					type,
				},
				{
					headers: {
						Accept: 'application/json',
					},
				},
			)
			.pipe(map(({url}) => url));
	}

	setup2FACode(code: string, password: string, type: MFAType) {
		return this.http.post<{backupCodes: string[]}>('/api/auth/setup2FA', {code, password, type});
	}

	redeemGift() {
		return this.http.post('/api/user/redeemGift', {});
	}

	getSessions() {
		return this.http.get('/api/auth/sessions', {});
	}

	terminateSession(sessionId?: string) {
		return this.http.post('/api/auth/terminateSession', sessionId ? {sessionId} : null);
	}

	disclosures(form: UpdateDisclosuresRequest) {
		return this.http.post<any>('/api/user/disclosures', form);
	}
}
