import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Store } from '@ngxs/store';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, filter, first, map, switchMap, tap } from 'rxjs/operators';

import { OnboardingData } from '@savvy/models/onboarding.model';
import {
	ActivityEntry,
	FbUser,
	Notification,
	OrderActivity,
	PortfolioNotification,
	Timestamp,
	Transactions,
} from '@savvy/models/store';
import { Firebase } from '@savvy/store/actions/firebase.actions';

export enum FirebaseSubscriptionType {
	User = 'user',
	Notifications = 'notifications',
	PortfolioNotifications = 'portfolioNotifications',
	Activities = 'activities',
}

@Injectable({
	providedIn: 'root',
})
export class FirebaseService {
	userId$ = this.fireAuth.authState.pipe(
		map((user) => user?.uid),
		filter((uid) => !!uid),
	);

	fbUserDoc$ = this.userId$.pipe(map((userId) => this.firestore.doc<FbUser>(`users/${userId}`)));

	fbNotification$ = this.userId$.pipe(
		switchMap((userId) =>
			this.firestore
				.collection('notificationMessage', (ref) => ref.where('uid', '==', userId).where('read', '==', false))
				.snapshotChanges()
		),
		 map((notificationsChange: any) =>
		 notificationsChange.map((item: any) => {
			 const data = item.payload.doc.data();
			 return { id: item.payload.doc.id, ...data };
		 }),
	 ),
	);

	fbUser$ = this.fbUserDoc$.pipe(switchMap((userDoc) => userDoc.valueChanges()));

	// TODO: remove limit of 100 entries from activities and notifications

	notifications$ = this.fbUserDoc$.pipe(
		switchMap((userDoc) =>
			userDoc
				.collection<Notification>('notifications', (ref) => ref.orderBy('created', 'desc').limit(100))
				.snapshotChanges(),
		),
		map((notificationsChange) =>
			notificationsChange.map((item) => {
				const data = item.payload.doc.data();
				return { id: item.payload.doc.id, ...data, created: (data.created as Timestamp).toDate() };
			}),
		),
	);

	portfolioNotifications$ = this.fbUserDoc$.pipe(
		switchMap((userDoc) =>
			userDoc
				.collection<PortfolioNotification>('portfolio_notifications', (ref) => ref.limit(100))
				.snapshotChanges(),
		),
		map((notificationsChange) =>
			notificationsChange.map((item) => {
				const data = item.payload.doc.data();
				return data;
			}),
		),
	);

	activity$ = this.fbUserDoc$.pipe(
		switchMap((userDoc) =>
			userDoc
				.collection<ActivityEntry>('activities', (ref) => ref.orderBy('createdAt', 'desc').limit(100))
				.snapshotChanges(),
		),
		map((activityChange) =>
			activityChange.map((item) => {
				const data = item.payload.doc.data();
				return {
					id: item.payload.doc.id,
					...data,
					createdAt: (data.createdAt as Timestamp).toDate(),
				} as ActivityEntry;
			}),
		),
	);

	fbOnboardingData$ = this.userId$.pipe(
		switchMap((userId) =>
			this.firestore
				.collection('onboarding')
				.doc(userId)
				.snapshotChanges()
				.pipe(map((snap) => snap.payload.data() as OnboardingData)),
		),
	);

	subscriptions = Object.fromEntries(Object.values(FirebaseSubscriptionType).map((key) => [key, null])) as Record<
		FirebaseSubscriptionType,
		Subscription
	>;

	constructor(
		private firestore: AngularFirestore,
		private fireStorage: AngularFireStorage,
		private fireAuth: AngularFireAuth,
		private store: Store,
	) { }

	getDocument(url: string) {
		return this.fireStorage
			.ref(`documents/${url}`)
			.getDownloadURL()
			.pipe(catchError(() => of(null))) as Observable<string | null>;
	}

	getUsersTransactions() {
		return this.fbUserDoc$.pipe(
			first(),
			switchMap((userDoc) => userDoc.collection('transactions').snapshotChanges()),
			map(
				(snap) =>
					snap.map((doc) => ({
						...doc.payload.doc.data(),
						id: doc.payload.doc.id,
					})) as unknown as Transactions[],
			),
			map((data) =>
				data.map((value) => ({
					...value,
					createdAt: (value.createdAt as Timestamp).toDate(),
					updatedAt: (value.updatedAt as Timestamp).toDate(),
				})),
			),
		);
	}

	getOrdersForActivityGroup(groupId: string) {
		return this.fbUserDoc$.pipe(
			first(),
			switchMap((userDoc) => userDoc.collection<OrderActivity>(`activities/${groupId}/orders`).snapshotChanges()),
			map((snap) =>
				snap.map((doc) => {
					const orderData = doc.payload.doc.data();
					return {
						id: doc.payload.doc.id,
						...orderData,
						createdAt: (orderData.createdAt as Timestamp).toDate(),
					} as OrderActivity;
				}),
			),
		);
	}

	deleteNotification(id: string) {
		return this.fbUserDoc$.pipe(switchMap((userDoc) => userDoc.collection('notifications').doc(id).delete()));
	}

	markNotificationAsRead(id: string) {
		return this.fbUserDoc$.pipe(
			switchMap((userDoc) => userDoc.collection('notifications').doc(id).update({ read: true })),
		);
	}

	listenTo(key: FirebaseSubscriptionType) {
		if (this.subscriptions[key]) {
			throw new Error('Listener is already attached');
		}

		switch (key) {
			case FirebaseSubscriptionType.User:
				this.subscriptions[key] = this.fbUser$.subscribe((user) =>
					this.store.dispatch(new Firebase.User.Received(user)),
				);
				break;
			case FirebaseSubscriptionType.Activities:
				this.subscriptions[key] = this.activity$.subscribe((entries) =>
					this.store.dispatch(new Firebase.Activity.Received(entries)),
				);
				break;
			case FirebaseSubscriptionType.Notifications:
				this.subscriptions[key] = this.notifications$.subscribe((entries) =>
					this.store.dispatch(new Firebase.Notification.Received(entries)),
				);
				break;
			case FirebaseSubscriptionType.PortfolioNotifications:
				this.subscriptions[key] = this.portfolioNotifications$.subscribe((entries) =>
					this.store.dispatch(new Firebase.PortfolioNotification.Received(entries)),
				);
				break;
		}
	}

	removeListener(key: FirebaseSubscriptionType) {
		this.subscriptions[key].unsubscribe();
		this.subscriptions[key] = null;
	}
}
