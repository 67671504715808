<button mat-icon-button class="close" (click)="closeClick.emit()">
	<mat-icon>keyboard_backspace</mat-icon>
</button>

<cdk-virtual-scroll-viewport #scrollArea [itemSize]="130">
	<section
		*cdkVirtualFor="let notification of notifications$ | async"
		[class.with-image]="notificationImageUrls[notification.type]"
		[attr.data-id]="notification.id"
	>
		<button mat-icon-button (click)="deleteNotification(notification)">
			<mat-icon>close</mat-icon>
		</button>
		<div class="unread-mark" [hidden]="notification.read"></div>

		<img *ngIf="notificationImageUrls[notification.type] as imageUrl" [src]="imageUrl">

		<p class="title">{{notification.localised.en_US.title}}</p>

		<ng-container [ngSwitch]="notification.type">
			<div *ngSwitchCase="NotificationType.ACCOUNT_CREATED">
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, s ed do eiusmod tempor ncididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur  adipiscing elit, sed do eiusmod tempor
			</div>

			<div *ngSwitchCase="NotificationType.ACCOUNT_UPGRADED">
				Your account has been upgraded to Haicred E-Trade for <b>FREE!</b> You now can trade in thousands of stocks and ETFS. Enjoy these 6 months on Haicred
			</div>

			<div *ngSwitchDefault>
				<div class="subtitle">{{notification.localised.en_US.subtitle}}</div>
				<div>{{notification.localised.en_US.text}}</div>
			</div>
		</ng-container>

		<p class="date">{{(notification.created | time) || (notification.created | date: 'MMMM d, y \'at\' HH:mm')}}</p>
	</section>
</cdk-virtual-scroll-viewport>
