import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'savvy-account-exist',
    templateUrl: './accountExists.html',
    styleUrls: ['./accountExists.scss'],
})
export class AccountExistComponent {


    @Output() public closesss: EventEmitter<any> = new EventEmitter<any>();

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {

    }


}