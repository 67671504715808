import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface AlertData {
	title?: string;
	content?: string;
	actions: (LabelValuePair & { action?: () => void })[];
}

@Component({
	selector: 'savvy-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: AlertData
	) { }
}
