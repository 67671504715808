import {coerceBooleanProperty, coerceNumberProperty} from '@angular/cdk/coercion';
import {Directive, ElementRef, HostBinding, Input, Renderer2} from '@angular/core';

const baseClass = 'savvyGain';
const positiveClass = `${baseClass}-positive`;
const negativeClass = `${baseClass}-negative`;
const uncertainClass = `${baseClass}-uncertain`;

@Directive({
	selector: '[savvyGain]',
})
export class GainDirective {
	private _savvyGainExtended = false;
	private _savvyGainNoArrow = false;
	private _savvyGainParenthesis = false;

	private openChild = (() => {
		const el = this.renderer.createElement('span');
		this.renderer.appendChild(el, this.renderer.createText('('));
		this.renderer.addClass(el, 'savvyGain-open');
		return el;
	})();
	private closeChild = (() => {
		const el = this.renderer.createElement('span');
		this.renderer.appendChild(el, this.renderer.createText(')'));
		this.renderer.addClass(el, 'savvyGain-close');
		return el;
	})();
	private nbspChild = this.renderer.createText('\xa0\xa0\xa0\xa0');

	constructor(private elementRef: ElementRef<HTMLElement>, private renderer: Renderer2) {
		this.renderer.addClass(this.elementRef.nativeElement, baseClass);
	}

	@Input()
	set savvyGain(value: number | string) {
		value = coerceNumberProperty(value);
		if (typeof value !== 'number') {
			this.renderer.addClass(this.elementRef.nativeElement, uncertainClass);
			return;
		} else {
			this.renderer.removeClass(this.elementRef.nativeElement, uncertainClass);
		}
		if (value < 0) {
			this.renderer.removeClass(this.elementRef.nativeElement, positiveClass);
			this.renderer.addClass(this.elementRef.nativeElement, negativeClass);
		} else {
			this.renderer.removeClass(this.elementRef.nativeElement, negativeClass);
			this.renderer.addClass(this.elementRef.nativeElement, positiveClass);
		}
	}

	@Input()
	@HostBinding('attr.savvyGainExtended')
	public get savvyGainExtended() {
		return this._savvyGainExtended;
	}

	public set savvyGainExtended(value) {
		this._savvyGainExtended = coerceBooleanProperty(value);
	}

	@Input()
	@HostBinding('attr.savvyGainNoArrow')
	public get savvyGainNoArrow() {
		return this._savvyGainNoArrow;
	}

	public set savvyGainNoArrow(value) {
		this._savvyGainNoArrow = coerceBooleanProperty(value);
	}

	@Input()
	@HostBinding('attr.savvyGainParenthesis')
	public get savvyGainParenthesis() {
		return this._savvyGainParenthesis;
	}

	public set savvyGainParenthesis(value) {
		this._savvyGainParenthesis = coerceBooleanProperty(value);
		if (this._savvyGainParenthesis) {
			this.renderer.appendChild(this.elementRef.nativeElement, this.openChild);
			this.renderer.appendChild(this.elementRef.nativeElement, this.closeChild);
			this.renderer.appendChild(this.elementRef.nativeElement, this.nbspChild);
		} else {
			this.renderer.removeChild(this.elementRef.nativeElement, this.nbspChild);
		}
	}
}
