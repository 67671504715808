import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';

import {AssetsModule} from '@savvy/components/assets/assets.module';
import { StockComponent } from './stock.component';



@NgModule({
  declarations: [StockComponent],
  imports: [
    CommonModule,
    AssetsModule,
    //lib
    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
  ],
  exports: [StockComponent],
})
export class StockModule { }
