import {ScrollingModule} from '@angular/cdk/scrolling';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {ColumnDirective, LazyColumnDirective, LazyTableComponent} from './lazy-table.component';

@NgModule({
	declarations: [LazyTableComponent, LazyColumnDirective, ColumnDirective],
	imports: [CommonModule, ScrollingModule],
	exports: [LazyTableComponent, LazyColumnDirective, ColumnDirective],
})
export class LazyTableModule {}
