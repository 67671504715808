import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';

import {CheckmarkComponent} from './checkmark.component';

@NgModule({
	declarations: [CheckmarkComponent],
	imports: [
		CommonModule,
		// lib
		MatIconModule,
	],
	exports: [CheckmarkComponent],
})
export class CheckmarkModule {}
