// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {firebase} from './firebase';
import {version} from './version';

export const environment = {
	secret: '84D7Éa5fb23^CE83775f27897U6d7bG83214D5e86d91279655F8y*5a6c6F2A8DA9A',
	production: false,
	inviteLink: 'https://app.haiservices.com.br/short/invite/dev',
	baseurl: 'https://alicorn.haiservices.com.br',
	contentGqlBaseurl: 'https://koala.haiservices.com.br/graphql',
	academyBaseurl: 'https://dev.haiservices.com.br',
	faqBaseurl: 'https://dev.haiservices.com.br',
	wsBaseUrl: 'wss://ws-dev.haiservices.com.br/connect',
	appIds: {
		android: 'com.eventsoft.savvyinvest',
		ios: 'com.eventsoft.savvyinvest',
	},
	version: version + '-dev',
	firebase,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
