import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {User} from '@savvy/models/store';
import {
	AddNotification,
	CancelClaimKeyPix,
	ConfirmClaimKeyPix,
	CreateBeneficiary,
	CreateClaimKeyPix,
	CreateKeyPix,
	CreateLimitPix,
	DeleteBeneficiary,
	MarkAsReadNotification,
	SendVerificationCodePix,
	StatementFilter,
	Summary,
	UpdateBeneficiary,
	UpdateLimitPix,
	VerificationCodePix,
} from '@savvy/models/store/account';

@Injectable({
	providedIn: 'root',
})
export class AccountService {
	constructor(private http: HttpClient) {}

	getBalance(date: string) {
		return this.http.get(`/api/digital/balance?date=${date}`);
	}

	getStatementFilter(statementFilter: StatementFilter) {
		return this.http.post('/api/digital/statementFilter', statementFilter);
	}

	getSummary() {
		return this.http.get<Summary>('/api/digital/summary');
	}

	getPeriodBalance(period: number) {
		return this.http.get(`/api/digital/periodBalance?period=${period}`);
	}
	getUser() {
		return this.http.get<User>('/api/user');
	}

	getUserDetailsOnboarding() {
		return this.http.get<any>('/api/user/detailsOnboarding');
	}

	updateContactUser(reqUpdateContact: any) {
		return this.http.put('/api/user/contact', reqUpdateContact);
	}

	listAllKeyPixUser() {
		return this.http.get<any>('/api/digital/listKeyPix');
	}

	createKeyPix(body: CreateKeyPix) {
		return this.http.post('/api/digital/generateKeyPix', body);
	}

	sendVerificationCodePix(body: SendVerificationCodePix) {
		return this.http.post('/api/digital/sendVerificationCodePix', body);
	}

	verificationCodePix(body: VerificationCodePix) {
		return this.http.post('/api/digital/verifyCodeKeyPIx', body);
	}

	deleteKeyPix(body: any) {
		return this.http.post('/api/digital/deleteKeyPix', body);
	}

	createClaimKeyPix(body: CreateClaimKeyPix) {
		return this.http.post('/api/digital/claimKeyPix', body);
	}

	confirmClaimKeyPix(body: ConfirmClaimKeyPix) {
		return this.http.post('/api/digital/confirmClaim', body);
	}

	cancelClaimKeyPix(body: CancelClaimKeyPix) {
		return this.http.post('/api/digital/cancelClaim', body);
	}

	consultLimitPix() {
		return this.http.get('/api/digital/gePixLimits');
	}

	createLimitPix(body: CreateLimitPix) {
		return this.http.post('/api/digital/createPixLimit', body);
	}

	updateLimitPix(body: UpdateLimitPix) {
		return this.http.post('/api/digital/updatePixLimit', body);
	}

	listBeneficiary() {
		return this.http.get('/api/digital/getBeneficiaryLimit');
	}

	deleteBeneficiary(body: DeleteBeneficiary) {
		return this.http.delete('/api/digital/deleteBeneficiaryLimit?id=' + `${body.id}`);
	}

	createBeneficiary(body: CreateBeneficiary) {
		return this.http.post('/api/digital/createBeneficiaryLimit', body);
	}

	updateBeneficiary(body: UpdateBeneficiary) {
		return this.http.post('/api/digital/updateBeneficiaryLimit', body);
	}
	getNotifications() {
		return this.http.get('/api/digital/getNotifications');
	}

	addNotification(body: AddNotification) {
		return this.http.post('/api/digital/addNotifications', body);
	}

	markAsReadNotification(body: MarkAsReadNotification) {
		return this.http.put('/api/digital/markAsRead', body);
	}

	checkKeyPix(body: any){
		return this.http.post('/api/digital/checkKeyPix', body);
	}
}
