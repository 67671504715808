import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngxs/store';
import {map} from 'rxjs';
import {first, switchMap} from 'rxjs/operators';
import {CoreService} from '@savvy/services/core.service';
import {CoreState} from '@savvy/store/state/core.state';

@Component({
	selector: 'savvy-stock',
	templateUrl: './stock.component.html',
	styleUrls: ['./stock.component.scss'],
})
export class StockComponent implements OnInit {
	selectedAssets = new Set<string>();

	constructor(
		private dialogRef: MatDialogRef<StockComponent>,
		private store: Store,
		private coreService: CoreService,
	) {}

	ngOnInit(): void {
		this.store
			.select(CoreState.watchlistLoners)
			.subscribe((watchlist) => watchlist.forEach((symbol) => this.selectedAssets.add(symbol)));
	}

	toggleAsset(symbol: string) {
		if (this.selectedAssets.has(symbol)) {
			this.selectedAssets.delete(symbol);
		} else {
			this.selectedAssets.add(symbol);
		}
	}

	save() {
		this.store
			.select(CoreState.watchlistLoners)
			.pipe(
				first(),
				map((watchlist) => {
					const toCreate = Array.from(this.selectedAssets).filter((symbol) => !watchlist.includes(symbol));
					const toDelete = watchlist.filter((symbol) => !this.selectedAssets.has(symbol));
					return {toCreate, toDelete};
				}),
				switchMap((data) => this.coreService.manageLoners(data)),
			)
			.subscribe(() => this.close());
	}

	close() {
		this.dialogRef.close();
	}
}
