<h1 *ngIf="data.title" mat-dialog-title>{{data.title}}</h1>
<div *ngIf="data.content" mat-dialog-content>{{data.content}}</div>
<div mat-dialog-actions align="end">
	<button
		*ngFor="let item of data.actions"
		mat-button
		color="primary"
		[mat-dialog-close]="item.value"
		(click)="item.action && item.action()"
	>{{item.label || item}}</button>
</div>
