import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
	transform(value: string, wordsCount: number, ellipsis = '…'): string {
		const split = value.split(' ');
		return split.slice(0, wordsCount).join(' ') + (split.length > wordsCount ? ellipsis : '');
	}
}
