import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'zipCode',
})
export class ZipCodePipe implements PipeTransform {
	transform(value: string): string {
		let zip = value + '';

		zip = zip
			.padStart(9, '')
			.substr(0, 9)
			.replace(/(\d{5})(\d{3})/, '$1-$2');

		return zip;
	}
}
