import {Injectable} from '@angular/core';
import {Action, createSelector, Selector, State, StateContext} from '@ngxs/store';
import {tap} from 'rxjs/operators';
import {handleStoreError} from '@savvy/helpers/handleStoreError';
import {FirebaseCourse} from '@savvy/models/store';
import {CoursesService} from '@savvy/services/courses.service';
import {Courses} from '@savvy/store/actions/courses.actions';
import {ErrorState} from '@savvy/store/state/error.state';

export interface CoursesStateModel {
	courses: FirebaseCourse.Course[];
	error: string;
}

@State({
	name: 'courses',
	defaults: {
		courses: [],
		error: null,
	},
})
@Injectable()
export class CoursesState extends ErrorState {
	constructor(private coursesService: CoursesService) {
		super();
	}

	static course(slug: string) {
		return createSelector([CoursesState], (state: CoursesStateModel) =>
			state.courses.find((item) => item.slug === slug),
		);
	}

	@Selector([CoursesState])
	static all(state: CoursesStateModel) {
		return state.courses;
	}

	@Action(Courses.Load)
	loadCourses(ctx: StateContext<CoursesStateModel>) {
		return this.coursesService.fetchCourses().pipe(
			tap((courses) => ctx.patchState({courses})),
			handleStoreError(ctx),
		);
	}

	@Action(Courses.LoadDetails)
	loadDetails(ctx: StateContext<CoursesStateModel>, action: Courses.LoadDetails) {
		const state = ctx.getState();
		if (state.courses.some((item) => item.slug === action.slug)) {
			return;
		}

		return this.coursesService.getCourseDetails(action.slug).pipe(
			tap((course) =>
				ctx.setState((existing) => ({
					...existing,
					courses: [...existing.courses, course],
				})),
			),
			handleStoreError(ctx),
		);
	}

	@Action(Courses.SaveQuiz)
	saveQuiz(ctx: StateContext<CoursesStateModel>, action: Courses.SaveQuiz) {
		return this.coursesService.saveQuiz(action.slug, action.answers).pipe(
			tap(({score}) =>
				ctx.setState((existing) => ({
					...existing,
					courses: existing.courses.map((item) =>
						item.slug === action.slug
							? {
									...item,
									score,
							  }
							: item,
					),
				})),
			),
			handleStoreError(ctx),
		);
	}

	@Action(Courses.CreateReview)
	createReview(ctx: StateContext<CoursesStateModel>, action: Courses.CreateReview) {
		return this.coursesService
			.createReview(action.slug, action.rating, action.feedback)
			.pipe(handleStoreError(ctx));
	}
}
