import {Component, HostBinding, Input} from '@angular/core';

@Component({
	selector: 'savvy-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
	@HostBinding('style.display')
	get display() {
		return this.enabled ? '' : 'none';
	}
	@HostBinding('attr.absolute')
	@Input()
	absolute = true;

	@HostBinding('style.background')
	@Input()
	background = '#ffffffaa';

	@Input()
	enabled = true;
	@Input()
	size = 100;
}
