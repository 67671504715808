import {DocumentStatus} from '@savvy/models/onboarding.model';
import {Order} from './portfolio';

export interface Credentials {
	username: string;
	password: string;
	code?: string;
}

export enum DriveWealthStatus {
	PENDING = 'PENDING',
	OPEN = 'OPEN',
	OPEN_NO_NEW_TRADES = 'OPEN_NO_NEW_TRADES',
	CLOSED = 'CLOSED',
}

export enum AccountStatus {
	ONBOARDING = 'Onboarding',
	VERIFYING = 'Verifying',
	MANUAL_VERIFICATION = 'Manual',
	REJECTED = 'Rejected',
	CLOSED = 'Closed',
	OPEN = 'Open',
}

export enum DocumentType {
	PICTURE_ID = 'PICTURE_ID',
	PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
	PICTURE_ID_PROOF_OF_ADDRESS = 'PICTURE_ID_PROOF_OF_ADDRESS',
	TAX = 'TAX',
	TRUST_DOCUMENTS = 'TRUST_DOCUMENTS',
}

export interface Profile {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	subscription: string | null;
	avatarUrl: string | null;
}

export interface Account {
	id: string;
	accountNo: string;
	status: DriveWealthStatus;
}

export interface EquityPosition {
	symbol: string;
	instrumentID: string;
	openQty: number;
	costBasis: number;
	marketValue: number;
	side: string;
	priorClose: number;
	availableForTradingQty: number;
	avgPrice: number;
	mktPrice: number;
	unrealizedPL: number;
	unrealizedDayPLPercent: number;
	unrealizedDayPL: number;
}

export interface AccountSummary {
	cashAvailableForTrade: number;
	cashAvailableForWithdrawal: number;
	cashBalance: number;
	equity: {
		equityPositions: EquityPosition[];
		equityValue: number;
	};
	orders: Order[];
}

export interface PhysicalDocument {
	documentID: string;
	type: {
		name: DocumentType;
		description: string;
	};
	receivedVia: string;
	/**
	 * ISO date
	 */
	receivedWhen: string;
	status: {
		// todo: should be enum
		name: string;
		description: string;
	};
}

export interface UpdatedAddress {
	cpf: string;
	expiration: string;
	status: DocumentStatus;
	data: {
		city: string;
		fullAddressStr: string;
		neighborhood: string;
		state: string;
		street: string;
		zip: string;
	};
}

export interface User {
	user: Profile;
	account: Account;
	accountSummary: AccountSummary;
	physicalDocuments: PhysicalDocument[];
}

export interface FbUser {
	status: AccountStatus;
	dwStatus: AccountStatus;
	topazioStatus: AccountStatus;
	unread: number;
	settings: {
		accountUpdatesPush: boolean;
		activitiesPush: boolean;
		corporateActionsPush: boolean;
		transactionsPush: boolean;
	};
	updatedAddress: UpdatedAddress | null;
}

export interface PersonalInfo {
	marital: string;
	partnerName: string;
}

export interface Address {
	city: string;
	fullAddressStr: string;
	street: string;
	state: string;
	zip: string;
	neighborhood: string;
}

export interface EmploymentInfo {
	status: string;
	position: string;
	company: string;
	type: string;
}

export interface InvestorProfileInfo {
	investmentExperience: string;
	annualIncome: number;
	networthTotal: number;
	riskTolerance: string;
	officialsName: string;
	officialsMembers: string;
	affiliatedCompanyName: string;
	affiliatedCompanySymbol: string;
	shareholderOrDirectorCompanyName: string;
	shareholderOrDirectorCompanySymbol: string;
	startDateOfOfficialsPosition: string;
	importanceOfLiquidity: string;
	investmentHorizon: string;
}

export interface IdentificationData {
	cpf: string;
	dob: string;
	firstName: string;
	lastName: string;
}

export interface UserDetails {
	personalData: PersonalInfo;
	addressData: Address;
	employmentData: EmploymentInfo;
	investmentData: InvestorProfileInfo;
	identificationData: IdentificationData;
}

export enum MFAType {
	APP = 'APP',
	SMS = 'SMS',
}

export enum TwoFactorSetupType {
	SMS,
	QR,
	Secret,
}

export interface LoginResponse extends User {
	status: {
		savvy: AccountStatus;
		dw: AccountStatus;
		topazio: AccountStatus;
	};
	mfaType?: MFAType;
	mfaEnabled: boolean;
	token: string;
	firebaseToken: string;
}

export interface CreateAccountRequest {
	fullname: string;
	birthDate: string;
	cpf: string;
	password: string;
	email: string;
	phone: string;
	inviteCode?: string;
	agreements: {
		termsOfUse: boolean;
		privacyPolicy: boolean;
		customerAgreement: boolean;
		w8Agreement: boolean;
	};
}

export interface CreateAccountResponse {
	token: string;
	firebaseToken: string;
}

export interface UpdatePersonalInfoRequest {
	documentNumber: string;
	issuingBody: string;
	stateIssuingDocument: string;
	dateIssuedDocument: string;
	nameMother: string;
	gender: string;
	birthCountry: string;
	birthState: string;
	cityBirth: string;
	birthStateForeigner: string;
	cityBirthForeigner: string;
	nationality: string;
}

export interface UpdatePersonalAddressRequest {
	zip: string;
	address: string;
	number: number;
	neighborhood: string;
	country: string;
	state: string;
	city: string;
}

export interface UpdateDisclosuresRequest {
	brokerAffiliate: {
		isBrokerAffiliate: boolean;
		nameCompanyAffiliateBroker?: string| null;
	};
	shareholder: {
		isShareholder: boolean;
		companyShareholder?: string| null;
		ticket?: string| null;
	};
	publicOffice: {
		ispublicOffice: boolean;
		companyShareholder?: string| null;
		ticket?: string| null;
	};
}

export interface SubscribeWaitingListRequest {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	gift: string;
	invities: Array<{
		firstName: string;
		lastName: string;
		email: string;
		gift: string;
	}>;
}

export interface Referrals {
	code: string | null;
	joined: {name: string; img: string}[];
}

export enum LoginProvider {
	Google = 'google',
	Apple = 'apple',
}

export interface ChangePasswordRequest{
	oldPassword: string;
	newPassword: string;
}


export interface NotificationContentLimit{
	name: string;
	limit: number;
	limitUpdate: number;
}
