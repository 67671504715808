import {Timestamp} from '@savvy/models/store/index';
import {OrderSide, OrderStatus} from '@savvy/models/store/portfolio';

export enum ActivityType {
	TRADE_CONFIRM = 'TRADE_CONFIRM',
	ORDER_GROUP = 'ORDER_GROUP',
	// order types
	MARKET_ORDER = 'MARKET',
	STOP = 'STOP',
	LIMIT = 'LIMIT',
	MARKET_IF_TOUCHED = 'MARKET_IF_TOUCHED',
	// corporate actions
	DIVIDENDS = 'DIVIDENDS',
	MERGE_ACQ = 'MERGE_ACQ',
}

export enum DividendsType {
	CASH = 'CASH',
	STOCK = 'STOCK',
	RETURN_OF_CAPITAL = 'RETURN_OF_CAPITAL',
	DISTRIBUTION_LONG = 'DISTRIBUTION_LONG',
	DISTRIBUTION_SHORT = 'DISTRIBUTION_SHORT',
	OTHER = 'OTHER',
}

export enum MergeAndAcqType {
	STOCK = 'STOCK',
	CASH = 'CASH',
	STOCK_AND_CASH = 'STOCK_AND_CASH',
}

export interface ActivityBase {
	id?: string;
	expanded?: boolean;
	createdAt: Timestamp | Date;
	type: ActivityType;
	payload: Record<string, any>;
}

export interface TradeConfirmationActivity extends ActivityBase {
	type: ActivityType.TRADE_CONFIRM;
	payload: {
		fileKey: string;
	};
}

export interface DividendsActivity extends ActivityBase {
	type: ActivityType.DIVIDENDS;
	payload: {
		id: string;
		type: DividendsType;
		amount: number;
		amountPerShare: number;
		symbol: string;
		pieIds: string[];
		split: Array<{
			id: string;
			name: string;
			amount: string;
		}>;
	};
}

export interface MergeAndAcqActivity extends ActivityBase {
	type: ActivityType.MERGE_ACQ;
	payload: {
		type: MergeAndAcqType;
		id: string;
		acquirer: string;
		acquiree: string;
		removed: number;
		added: number;
		cash: number;
		pieIds: string[];
		split: Array<{
			id: string;
			name: string;
			removed: number;
			added: number;
		}>;
	};
}

export interface OrderGroupActivity extends ActivityBase {
	orders: OrderActivity[];
	type: ActivityType.ORDER_GROUP;
	payload: {
		pie: string;
		pieIds: [string];
		buying: string[];
		selling: string[];
	};
}

export interface OrderActivity extends ActivityBase {
	type: ActivityType.MARKET_ORDER | ActivityType.LIMIT | ActivityType.STOP | ActivityType.MARKET_IF_TOUCHED;
	payload: {
		averagePrice: number;
		createdAt: Timestamp | Date;
		history: Array<{
			createdAt: Timestamp | Date;
			status: OrderStatus;
		}>;
		orderId: string;
		errorMessage: string;
		requestedPrice: number;
		orderNo: string;
		pie: string;
		pieIds: [string];
		quantity: number;
		side: OrderSide;
		status: OrderStatus;
		symbol: string;
		totalPrice: number | null;
	};
}

export type ActivityEntry = TradeConfirmationActivity | DividendsActivity | MergeAndAcqActivity | OrderGroupActivity | OrderActivity;
