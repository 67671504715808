import {ChartPeriod, PriceSubscribeType, SocketServerEvent} from '@savvy/models/store/stream';

export namespace Stream {
	export namespace Subscribe {
		export class Fx {
			static readonly type = '[Stream] Subscribe To FX';
		}

		export class MarketStatus {
			static readonly type = '[Stream] Subscribe To Market Status';
		}

		export class Price {
			static readonly type = '[Stream] Subscribe To Price';

			constructor(public type: PriceSubscribeType, public id: string | null) {}
		}

		export class Chart {
			static readonly type = '[Stream] Subscribe To Chart';

			constructor(public type: PriceSubscribeType, public period: ChartPeriod, public id: string | null) {}
		}
	}

	export namespace Unsubscribe {
		export class Fx {
			static readonly type = '[Stream] Unsubscribe From FX';
		}

		export class MarketStatus {
			static readonly type = '[Stream] Unsubscribe From Market Status';
		}

		export class Price {
			static readonly type = '[Stream] Unsubscribe From Price';

			constructor(public type: PriceSubscribeType, public id: string | null) {}
		}

		export class Chart {
			static readonly type = '[Stream] Unsubscribe From Chart';

			constructor(public type: PriceSubscribeType, public period: ChartPeriod, public id: string | null) {}
		}
	}

	export class Auth {
		static readonly type = '[Stream] Auth';

		constructor(public token: string) {}
	}

	export class SaveEvent {
		static readonly type = '[Stream] Save Event';

		constructor(public event: SocketServerEvent) {}
	}
}
