import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialogConfig} from '@angular/material/dialog';
import {Select, Store} from '@ngxs/store';
import {Subscription} from 'rxjs';

import {tap} from 'rxjs/operators';
import {AssetsDataSource} from '@savvy/datasources/assets.datasource';
import {PortfolioService} from '@savvy/services/portfolio.service';
import {UtilService} from '@savvy/services/util.service';
import {CoreState} from '@savvy/store/state/core.state';

type AssetsListMode = 'select' | 'browse';

export const DEFAULT_ASSETS_DIALOG_CONFIG: MatDialogConfig = {
	panelClass: ['panel-with-shadow', 'panel-without-paddings'],
	width: '1100px',
};

@Component({
	selector: 'savvy-assets',
	templateUrl: './assets.component.html',
	styleUrls: ['./assets.component.scss'],
})
export class AssetsComponent implements OnInit, OnDestroy {
	@Select(CoreState.watchlistLoners)
	watchlist$: string[];
	@Select(CoreState.portfolioLoners)
	portfolio$: string[];

	@Input()
	mode: AssetsListMode = 'select';
	@Input()
	title = 'Results';
	@Input()
	selected: Set<string> = new Set();
	@Output()
	symbolClick = new EventEmitter<string>();

	assetsSubscription: Subscription;

	displayedColumns: string[] = [];
	dataSource = new AssetsDataSource([], this.store, this.portfolioService, this.utilService);

	categories$ = this.portfolioService.getCategories().pipe(tap((value) => console.log(value)));
	selectedCategories = null as string;

	constructor(private store: Store, private portfolioService: PortfolioService, private utilService: UtilService) {}

	toggleAsset(symbol: string) {
		this.symbolClick.emit(symbol);
	}

	onSearch(search: string) {
		this.dataSource.search$.next(search);
	}

	selectCategory(category: string) {
		this.selectedCategories = category;
		this.dataSource.category$.next(category);
	}

	ngOnInit() {
		this.displayedColumns =
			this.mode === 'select'
				? ['name', 'change', 'price', 'checkmark']
				: ['name', 'price', 'change', 'yield', 'peRatio', 'status'];
		this.assetsSubscription = this.store.select(CoreState.instruments).subscribe((assets) => {
			this.dataSource.data = [...assets];
		});
	}

	ngOnDestroy() {
		this.assetsSubscription.unsubscribe();
	}
}
