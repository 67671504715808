import {animate, style, transition, trigger} from '@angular/animations';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {fromEvent, Observable, Subscription} from 'rxjs';
import {debounceTime, delay, first, withLatestFrom} from 'rxjs/operators';

import {Notification, NotificationType} from '@savvy/models/store';
import {Firebase} from '@savvy/store/actions/firebase.actions';
import {FirebaseState} from '@savvy/store/state/firebase.state';

@Component({
	selector: 'savvy-notifications',
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.scss'],
	animations: [
		trigger('notificationTrigger', [
			transition(':enter', [
				style({transform: 'translateX(200px)', opacity: '0'}),
				animate('300ms cubic-bezier(.5,.3,.4, 1)', style({transform: 'translateX(0)', opacity: '1'})),
			]),
			transition(':leave', [
				animate(
					'300ms cubic-bezier(.5,.3,.4, 1)',
					style({
						transform: 'translateX(200px)',
						opacity: '0',
					}),
				),
			]),
		]),
	],
})
export class NotificationsComponent implements OnInit, AfterViewInit, OnDestroy {
	@Output()
	closeClick = new EventEmitter<void>();

	@ViewChild('scrollArea')
	scrollArea: CdkVirtualScrollViewport;

	@Select(FirebaseState.notifications)
	notifications$: Observable<Notification[]>;

	renderMoreSub: Subscription;
	setReadSub: Subscription;

	NotificationType = NotificationType;

	notificationImageUrls: Record<NotificationType, string> = {
		[NotificationType.ACCOUNT_CREATED]: 'assets/icons/icon-512x512.png',
		[NotificationType.ACCOUNT_UPGRADED]: 'assets/notifications/gratters.svg',
	};

	constructor(private store: Store, public elementRef: ElementRef) {}

	@HostBinding('@notificationTrigger')
	get notificationTrigger() {
		return true;
	}

	deleteNotification(notification: Notification) {
		const scrollElem = this.scrollArea.elementRef.nativeElement;
		const notificationElem = scrollElem.querySelector(`[data-id="${notification.id}"]`);
		const animation = notificationElem.animate(
			[
				{transform: 'scale(1)', opacity: '1'},
				{transform: 'scale(0.7)', opacity: '0'},
			],
			{duration: 200},
		);
		animation.onfinish = () => {
			notificationElem.remove();
			this.store.dispatch(new Firebase.Notification.Delete(notification.id));
		};
	}

	setNotificationsInViewportRead(notifications: Notification[]) {
		const scrollElem = this.scrollArea._contentWrapper.nativeElement;
		const {top, bottom} = scrollElem.getBoundingClientRect();

		Array.from(scrollElem.children).forEach((notificationElem: HTMLElement) => {
			const {top: notificationTop, bottom: notificationBottom} = notificationElem.getBoundingClientRect();

			if (notificationTop >= top - 50 && notificationBottom <= bottom + 50) {
				const notification = notifications.find(({id}) => notificationElem.dataset.id === id);

				if (notification && !notification.read) {
					this.store.dispatch(new Firebase.Notification.MarkAsRead(notification.id));
				}
			}
		});
	}

	ngOnInit() {
		this.store.dispatch(new Firebase.Notification.Subscribe());
	}

	ngAfterViewInit() {
		const scrollElem = this.scrollArea.elementRef.nativeElement;
		const scroll$ = fromEvent(scrollElem, 'scroll').pipe(withLatestFrom(this.notifications$));

		this.setReadSub = scroll$.pipe(debounceTime(1500)).subscribe(([, notifications]) => {
			this.setNotificationsInViewportRead(notifications);
		});
		this.notifications$
			.pipe(first(), delay(1500))
			.subscribe((notifications) => this.setNotificationsInViewportRead(notifications));
	}

	ngOnDestroy() {
		this.store.dispatch(new Firebase.Notification.Unsubscribe());
		this.renderMoreSub?.unsubscribe();
		this.setReadSub?.unsubscribe();
	}
}
