import {Platform} from '@angular/cdk/platform';
import {Injectable} from '@angular/core';
import {CanLoad, Route, Router, UrlSegment} from '@angular/router';
import {map} from 'rxjs/operators';

import {UtilService} from '@savvy/services/util.service';
import {environment} from '../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class MobileGuard implements CanLoad {
	isSmallScreen$ = this.utilService.isSmallScreen$;

	constructor(private router: Router, private platform: Platform, private utilService: UtilService) {}

	canLoad(route: Route, segments: UrlSegment[]) {
		return this.isSmallScreen$.pipe(
			map((blocked) => {
				if (blocked) {
					if (segments[0].toString() === 'auth' && (this.platform.ANDROID || this.platform.IOS)) {
						window.location.href = this.platform.ANDROID
							? `market://details?id=${environment.appIds.android}`
							: `itms-apps://itunes.apple.com/app/${environment.appIds.ios}`;
					} else {
						void this.router.navigate(['home']);
					}
				}

				return !blocked;
			}),
		);
	}
}
