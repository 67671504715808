import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {PreloadImageDirective} from './preload-image.directive';

@NgModule({
	declarations: [PreloadImageDirective],
	imports: [CommonModule],
	exports: [PreloadImageDirective],
})
export class PreloadImageModule {}
