<div class="tooltip-conatiner">
	<img src="./assets/qr-code/wallet-qr.svg" />
	<p class="subtitle">
		This feature isn`t available in the web version. Please, scan the QR code with your phone and proceed from the
		mobile app
	</p>
</div>

<button mat-icon-button class="close" (click)="close()">
	<mat-icon>close</mat-icon>
</button>
