import {Pipe, PipeTransform} from '@angular/core';
import {DateTime} from 'luxon';

const MINUTE = 1000 * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

@Pipe({
	name: 'time',
})
export class TimePipe implements PipeTransform {
	transform(value: Date | string | number): string | null {
		const then = DateTime.fromJSDate(new Date(value));
		const passed = -then.diffNow().milliseconds;

		const todayStart = DateTime.local().startOf('day');

		return +passed < MINUTE
			? 'just now'
			: +passed < DAY / 2
			? then.toRelative()
			: +passed < DAY && then.toMillis() >= todayStart.toMillis()
			? `today at ${this.getTime(then)}`
			: +passed < DAY * 2 && then.toMillis() >= todayStart.minus({days: 1}).toMillis()
			? `yesterday at ${this.getTime(then)}`
			: null;
	}

	getTime(time: DateTime) {
		return `${time.hour.toString().padStart(2, '0')}:${time.minute.toString().padStart(2, '0')}`;
	}
}
