export enum SocketEventType {
	Auth = 'AUTH',
	ReplyStatus = 'REPLY_STATUS',
	Refresh = 'REFRESH',
	FxRate = 'FX_RATE',
	FxSubscribe = 'FX_SUBSCRIBE',
	FxUnsubscribe = 'FX_UNSUBSCRIBE',
	MarketStatus = 'MARKET_STATUS',
	MarketStatusSubscribe = 'MARKET_STATUS_SUBSCRIBE',
	MarketStatusUnsubscribe = 'MARKET_STATUS_UNSUBSCRIBE',
	Price = 'PRICE',
	PriceSubscribe = 'PRICE_SUBSCRIBE',
	PriceUnsubscribe = 'PRICE_UNSUBSCRIBE',
	Chart = 'CHART',
	ChartSubscribe = 'CHART_SUBSCRIBE',
	ChartUnsubscribe = 'CHART_UNSUBSCRIBE',
}

export enum PriceSubscribeType {
	Stock = 'stock',
	Pie = 'pie',
	Portfolio = 'portfolio',
}

export enum MarketStatus {
	PreOpen = 'PRE_OPEN',
	AfterHours = 'AFTER_HOURS',
	Open = 'OPEN',
	Closed = 'CLOSED',
	Holiday = 'HOLIDAY',
}

export enum ChartPeriod {
	Days1 = 'DAYS1',
	Weeks1 = 'WEEKS1',
	Months1 = 'MONTHS1',
	Months3 = 'MONTHS3',
	Quarters1 = 'QUARTERS1',
	Years1 = 'YEARS1',
	Years2 = 'YEARS2',
	Years3 = 'YEARS3',
	Years5 = 'YEARS5',
	All = 'ALL',
}

export enum ReplyStatus {
	Success = 'SUCCESS',
	Forbidden = 'FORBIDDEN',
}

export enum RefreshEventType {
	Portfolio = 'portfolio',
	Watchlist = 'watchlist',
	Holdings = 'holdings',
	Activity = 'activity',
	Cash = 'cash',
	User = 'user',
}

export interface GeneralSocketEvent<T, K extends Record<string, unknown> | void> {
	e: T;
	p: K;
}

export type FxSubscribeEvent = GeneralSocketEvent<SocketEventType.FxSubscribe, void>;
export type FxUnsubscribeEvent = GeneralSocketEvent<SocketEventType.FxUnsubscribe, void>;
export type AuthEvent = GeneralSocketEvent<SocketEventType.Auth, {token: string}>;
export type MarketSubscribeEvent = GeneralSocketEvent<SocketEventType.MarketStatusSubscribe, void>;
export type MarketUnsubscribeEvent = GeneralSocketEvent<SocketEventType.MarketStatusUnsubscribe, void>;
export type PriceSubscribeEvent = GeneralSocketEvent<
	SocketEventType.PriceSubscribe,
	{
		type: PriceSubscribeType;
		id: string | null;
	}
>;
export type PriceUbsubscribeEvent = GeneralSocketEvent<
	SocketEventType.PriceUnsubscribe,
	{
		type: PriceSubscribeType;
		id: string | null;
	}
>;
export type ChartSubscribe = GeneralSocketEvent<
	SocketEventType.ChartSubscribe,
	{
		type: PriceSubscribeType;
		period: ChartPeriod;
		id: string | null;
	}
>;
export type ChartUnsubscribe = GeneralSocketEvent<
	SocketEventType.ChartUnsubscribe,
	{
		type: PriceSubscribeType;
		period: ChartPeriod;
		id: string | null;
	}
>;

export interface PriceSlice {
	id: string;
	fraction: string;
}

export interface PriceData {
	lastTrade: string;
	dailyChange: string;
	dailyChangePct: string;
	afterHoursChange: string;
	afterHoursChangePct: string;
	totalChange: string;
	totalChangePct: string;
	slices: PriceSlice[];
	failedSlices: string[];
}

export interface MarketStatusData {
	status: MarketStatus | null;
	nextStatus: MarketStatus | null;
	msUntilNextStatus: number | null;
}

export interface ChartDataSlice {
	symbol: string;
	percentFromInitial: string;
	price: string;
	changeValue: string;
}

export interface ChartDataEntry {
	date: string;
	percentFromInitial: string;
	price: string;
	changeValue: string;
	slices: ChartDataSlice[] | null;
}

export type PriceEvent = GeneralSocketEvent<
	SocketEventType.Price,
	{
		type: PriceSubscribeType;
		id: string | null;
		data: PriceData;
	}
>;
export type FxRateEvent = GeneralSocketEvent<SocketEventType.FxRate, {fxRate: string}>;
export type MarketStatusEvent = GeneralSocketEvent<
	SocketEventType.MarketStatus,
	{
		status: MarketStatus;
		nextStatus: MarketStatus;
		msUntilNextStatus: number;
	}
>;
export type ChartEvent = GeneralSocketEvent<
	SocketEventType.Chart,
	{
		type: PriceSubscribeType;
		id: string | null;
		period: ChartPeriod;
		data: ChartDataEntry[];
	}
>;
export type ReplyStatusEvent = GeneralSocketEvent<
	SocketEventType.ReplyStatus,
	{
		responseFor: {
			e: SocketEventType;
			rid: string | null;
		};
		status: ReplyStatus;
	}
>;
export type RefreshEvent = GeneralSocketEvent<
	SocketEventType.Refresh,
	{
		type: RefreshEventType;
	}
>;

export type UnknownEvent = GeneralSocketEvent<void, void>;

export type SocketClientEvent =
	| FxSubscribeEvent
	| FxUnsubscribeEvent
	| AuthEvent
	| MarketSubscribeEvent
	| MarketUnsubscribeEvent
	| PriceSubscribeEvent
	| PriceUbsubscribeEvent
	| ChartSubscribe
	| ChartUnsubscribe;

export type SocketServerEvent =
	| FxRateEvent
	| MarketStatusEvent
	| PriceEvent
	| ChartEvent
	| ReplyStatusEvent
	| RefreshEvent
	| UnknownEvent;
