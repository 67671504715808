import {Component, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';

@Component({
	selector: 'savvy-checkmark',
	templateUrl: './checkmark.component.html',
	styleUrls: ['./checkmark.component.scss'],
})
export class CheckmarkComponent {
	@Input()
	@HostBinding('attr.checked')
	value = false;
	@Input()
	@HostBinding('attr.disabled')
	disabled = false;
	@Output()
	valueChanged = new EventEmitter<boolean>();

	constructor() {}

	@HostListener('click', ['$event'])
	onClick(event: MouseEvent) {
		if (this.disabled) {
			return;
		}
		event.preventDefault();
		event.stopPropagation();

		this.value = !this.value;
		this.valueChanged.emit(this.value);
	}
}
