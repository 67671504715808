import {coerceNumberProperty} from '@angular/cdk/coercion';
import {Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';

import {readCSSVar} from '@savvy/helpers/readCSSVar';

const twoPi = Math.PI * 2;

@Component({
	selector: 'savvy-wave',
	templateUrl: './wave.component.html',
	styleUrls: ['./wave.component.scss'],
})
export class WaveComponent implements OnInit, OnDestroy {
	@ViewChild('canvas', {static: true})
	canvas: ElementRef<HTMLCanvasElement>;

	@Input()
	speed = 1;
	@Input()
	public get colorSet() {
		return this._colorSet;
	}
	public set colorSet(value) {
		this._colorSet = coerceNumberProperty(value);
	}
	private _colorSet = 0;
	@Input()
	public get period() {
		return this._period;
	}
	public set period(value) {
		this._period = coerceNumberProperty(value);
	}
	private _period = 4;
	@Input()
	public get amplitude() {
		return this._amplitude;
	}
	public set amplitude(value) {
		this._amplitude = coerceNumberProperty(value);
	}
	private _amplitude = 70;

	private memoryCanvas = document.createElement('canvas');

	private readonly _colors = [
		//['#9D2685', '#F5263C', '#F9A340', '#F9A340'],
		['#003087', '#155997', '#3DA6B7', '#57D9CB'],
		[readCSSVar('--web-primary'), readCSSVar('--web-warn'), readCSSVar('--web-accent')],
	];
	private get colors() {
		return this._colors[this.colorSet];
	}
	private readonly _offsetDiffs = [
		[0.1, 0.4, 0.4, 0.1],
		[0.1, 0.5, 0.4],
	];
	private get offsetDiffs() {
		return this._offsetDiffs[this.colorSet];
	}

	private drawFrameId: number;
	private animateFrameId: number;
	private lastTimestamp = 0;

	private ctx: CanvasRenderingContext2D;
	private offsetX = 0;

	private width: number;
	private height: number;

	constructor(private ngZone: NgZone, private elementRef: ElementRef<HTMLElement>) {}

	getGradient(ctx: CanvasRenderingContext2D, repeats: number) {
		const gradient = ctx.createLinearGradient(0, 0, ctx.canvas.width, 0);
		let offset = 0;
		const max = this.colors.length - 1;
		gradient.addColorStop(offset, this.colors[0]);
		for (let i = 0; i < repeats; i++) {
			for (let index = 1; index <= max; index++) {
				offset += this.offsetDiffs[index] / repeats / max;
				gradient.addColorStop(offset, this.colors[index]);
			}
			for (let index = max - 1; index >= 0; index--) {
				offset += (1 - this.offsetDiffs[index + 1]) / repeats / max;
				gradient.addColorStop(offset, this.colors[index]);
			}
		}
		return gradient;
	}

	memorizeWave() {
		const ctx = this.memoryCanvas.getContext('2d');
		ctx.fillStyle = this.getGradient(ctx, 2);

		const width = ctx.canvas.width;
		ctx.clearRect(0, 0, width, this.height);
		ctx.beginPath();

		ctx.moveTo(0, this.height);
		for (let x = 0; x <= width; x++) {
			const relX = (twoPi * x) / width;
			const y = this.amplitude * Math.sin(relX * this.period) + this.amplitude;
			ctx.lineTo(x, y);
		}
		ctx.lineTo(width, this.height);
		ctx.lineTo(0, this.height);

		ctx.fill();
	}

	draw() {
		this.ctx.clearRect(0, 0, this.width, this.height);
		this.ctx.drawImage(this.memoryCanvas, -this.offsetX, 0);
		this.drawFrameId = requestAnimationFrame(() => this.draw());
	}

	animate(timestamp: number) {
		const delta = ((timestamp - this.lastTimestamp) * this.speed) / 10;
		this.offsetX = (this.offsetX + delta) % (this.width * 2);
		this.animateFrameId = requestAnimationFrame((t) => this.animate(t));
		this.lastTimestamp = timestamp;
	}

	ngOnInit() {
		this.width = this.elementRef.nativeElement.clientWidth;
		this.canvas.nativeElement.width = this.width;
		this.memoryCanvas.width = this.width * 4;
		this.height = this.canvas.nativeElement.height = this.memoryCanvas.height = this.elementRef.nativeElement.clientHeight;

		this.memorizeWave();
		this.ctx = this.canvas.nativeElement.getContext('2d');

		this.ngZone.runOutsideAngular(() =>
			setTimeout(() => {
				this.draw();
				this.animate(0);
			}),
		);
	}

	ngOnDestroy() {
		cancelAnimationFrame(this.drawFrameId);
		cancelAnimationFrame(this.animateFrameId);
	}
}
