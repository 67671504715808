import {StateClass} from '@ngxs/store/internals';
import {AuthState} from './auth.state';
import {CoreState} from './core.state';
import {CoursesState} from './courses.state';
import {FirebaseState} from './firebase.state';
import {StreamState} from './stream.state';
import {SubscriptionState} from './subscription.state';

export const states: StateClass[] = [AuthState, FirebaseState, CoreState, CoursesState, StreamState, SubscriptionState];
export const statesToPreserve: StateClass[] = [AuthState];
