import {StateContext} from '@ngxs/store';
import {ObservableInput, ObservedValueOf, OperatorFunction, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {handleHttpError} from '@savvy/helpers/handleHttpError';

export const handleStoreError = <T, O extends ObservableInput<any>>(
	ctx: StateContext<{error: string}>,
): OperatorFunction<T, T | ObservedValueOf<O>> =>
	catchError((error) => {
		ctx.patchState(handleHttpError(error));
		return throwError(() => error);
	});

export const clearStoreError = (ctx: StateContext<{error: string}>) => ctx.patchState({error: null});
