<div class="table">
	<div class="savvy-tr savvy-header-row">
		<div class="savvy-th" *ngFor="let col of columnsToDisplay">
			{{ col.savvyLazyColumnName }}
		</div>
	</div>

	<div class="data">
		<cdk-virtual-scroll-viewport itemSize="60" [style.height.px]="height">
			<div class="savvy-tr" *cdkVirtualFor="let element of data$ | async" (click)="rowClick.emit(element)">
				<ng-container *ngFor="let col of columnsToDisplay">
					<ng-container *ngTemplateOutlet="col.templateRef; context: {$implicit: element}"></ng-container>
				</ng-container>
			</div>
		</cdk-virtual-scroll-viewport>
	</div>
</div>
