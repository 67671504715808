import {CdkOverlayOrigin} from '@angular/cdk/overlay';
import {Component, ElementRef, HostBinding, HostListener, OnInit, ViewChild} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {RouterState} from '@ngxs/router-plugin';
import {Select, Store} from '@ngxs/store';
import AOS from 'aos';
import {Observable, Subject} from 'rxjs';
import {filter, first, map, skipWhile, switchMap} from 'rxjs/operators';

import {DEFAULT_ASSETS_DIALOG_CONFIG} from '@savvy/components/assets/assets.component';
import {InviteCodeComponent} from '@savvy/components/invite-code/invite-code.component';
import {AuthScreen} from '@savvy/models/auth.model';
import {AccountStatus, Profile} from '@savvy/models/store';
import {BypassService} from '@savvy/services/bypass.service';
import {FirebaseService} from '@savvy/services/firebase.service';
import {NotificationsPortalService} from '@savvy/services/notifications-portal.service';
import {TooltipService} from '@savvy/services/tooltip.service';
import {UtilService} from '@savvy/services/util.service';
import {Auth} from '@savvy/store/actions/auth.actions';
import {Core} from '@savvy/store/actions/core.actions';
import {Firebase} from '@savvy/store/actions/firebase.actions';
import {Stream} from '@savvy/store/actions/stream.actions';
import {AuthState} from '@savvy/store/state/auth.state';
import {FirebaseState} from '@savvy/store/state/firebase.state';
import {SearchComponent} from './components/search/search.component';

@Component({
	selector: 'savvy-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	@ViewChild('bell')
	bellButton: MatButton;
	@ViewChild('avatar')
	menuOverlayOrigin: CdkOverlayOrigin;
	@ViewChild('accountMenu')
	accountMenuElement: ElementRef;

	@HostBinding('attr.source')
	openedFrom: SourceType;

	@Select(FirebaseState.userStatus)
	readonly accountStatus$!: Observable<AccountStatus>;
	@Select(AuthState.user)
	readonly user$!: Observable<Profile>;
	@Select(AuthState.isLoggedIn)
	readonly loggedIn$!: Observable<boolean>;
	@Select(FirebaseState.unread)
	readonly unread$!: Observable<boolean>;
	@Select(AuthState.inviteCode)
	readonly inviteCode$: Observable<string | null>;

	currentUrl$ = this.store.select(RouterState.url).pipe(filter(Boolean));

	avatarUrl$ = this.user$.pipe(
		filter(Boolean),
		switchMap((user) => this.firebaseService.getDocument(user.avatarUrl)),
	);

	readonly academyBaseUrl = this.bypassService.get('academyBaseUrl');
	readonly faqBaseUrl = this.bypassService.get('faqBaseUrl');

	readonly guestLinks: LabelValuePair<string, string>[] = [{label: 'Waiting List', value: '/waiting-list'}];
	readonly fullUserLinks: LabelValuePair<string, string>[] = [
		{label: 'Invest', value: '/invest'},
		{label: 'Wallet', value: '/wallet'},
		{label: 'Education', value: '/education'},
	];
	readonly userLinks: LabelValuePair<string, string>[] = [{label: 'Education', value: '/education'}];
	readonly subLinks: {parent: string; data: LabelValuePair<string, string>[]}[] = [
		{
			parent: '/invest',
			data: [
				{label: 'Portfolio', value: '/invest/portfolio'},
				{label: 'Holdings', value: '/invest/holdings'},
				{label: 'Create Pie', value: null},
			],
		},
		{
			parent: '/wallet',
			data: [
				{label: 'Deposit', value: null, tooltip: true, opacity: true},
				{label: 'Exchange	', value: null, tooltip: true, opacity: true},
				{label: 'Withdraw', value: null, tooltip: true, opacity: true},
				{label: 'Transactions', value: '/invest/activity'},
			],
		},
	];
	readonly fullProfileLinks: LabelValuePair<string, string>[] = [
		{label: 'Profile', value: '/profile-editing/profile'},
		{label: 'Bank & Brokerage Accounts', value: '/account/bank-accounts'},
		{label: 'Sessions', value: '/profile-editing/sessions'},
		{label: 'Statements', value: '/account/statements'},
		{label: 'Tax Documents', value: '/account/tax-documents'},
		{label: 'Confirmations', value: '/account/confirmations'},
		{label: 'VETs', value: '/account/vets'},
		{label: 'Settings', value: '/profile-editing/settings'},
		{label: 'Log out', value: null, click: () => this.onLogout()},
		{label: 'dev_hacks', value: '/admin'},
	];

	isMenuOverlayOpen = false;
	isMobileMenuOpen = false;

	isAuthPage$ = this.currentUrl$.pipe(map((url) => url.startsWith('/auth')));

	links$ = this.bypassService
		.get$('showFullVersion')
		.pipe(
			switchMap((showFullVersion) =>
				this.loggedIn$.pipe(
					map((loggedIn) =>
						loggedIn ? (showFullVersion ? this.fullUserLinks : this.userLinks) : this.guestLinks,
					),
				),
			),
		);
	hoveringOn$ = new Subject<string>();
	subLinks$ = this.hoveringOn$.pipe(map((url) => url && this.subLinks.find((item) => url === item.parent)?.data));

	createAccScreen$ = this.currentUrl$.pipe(map((url) => url.includes(AuthScreen.CreateAccount)));

	constructor(
		private store: Store,
		private util: UtilService,
		private route: ActivatedRoute,
		private router: Router,
		private tooltipService: TooltipService,
		private notificationsPortalService: NotificationsPortalService,
		private firebaseService: FirebaseService,
		private bypassService: BypassService,
		public matDialog: MatDialog,
	) {}

	get profileLinks() {
		return this.showFullVersion ? this.fullProfileLinks : [];
	}

	get showFullVersion() {
		return this.bypassService.get('showFullVersion');
	}

	@HostListener('window:click', ['$event.target'])
	onClick(target: Element) {
		if (
			this.notificationsPortalService.component &&
			!this.notificationsPortalService.component.elementRef.nativeElement.contains(target) &&
			!this.bellButton._elementRef.nativeElement.contains(target)
		) {
			this.notificationsPortalService.close();
		}

		if (
			this.accountMenuElement &&
			!this.accountMenuElement.nativeElement.contains(target) &&
			!this.menuOverlayOrigin.elementRef.nativeElement.contains(target)
		) {
			this.closeAccountMenu();
		}
	}

	onLogout() {
		localStorage.removeItem('authLogin');
		this.store.dispatch(new Auth.Logout());
		this.store.dispatch(new Firebase.User.Logout());
		this.notificationsPortalService.close();
		this.router.navigateByUrl('/auth/login');
	}

	closeAccountMenu() {
		this.isMenuOverlayOpen = false;
	}

	toggleAccountMenu() {
		this.isMenuOverlayOpen = !this.isMenuOverlayOpen;
	}

	toggleNotifications() {
		this.notificationsPortalService.toggle();
	}

	toggleMobileMenu(state: boolean) {
		this.isMobileMenuOpen = state;
	}

	ngOnInit() {
		this.store
			.select(AuthState.token)
			.pipe(skipWhile((token) => !token))
			.subscribe((token) => {
				if (token) {
					this.store.dispatch(new Core.GetInstruments());
					this.store.dispatch(new Stream.Auth(token));
					this.store.dispatch(new Firebase.User.Subscribe());
				} else {
					this.store.dispatch(new Firebase.User.Unsubscribe());
				}
			});
		this.util.coldBoot();
		this.route.fragment.subscribe((fragment) =>
			setTimeout(() => document.getElementById(fragment)?.scrollIntoView({behavior: 'smooth'})),
		);
		this.route.queryParams
			.pipe(map(({source}) => source as SourceType))
			.subscribe((source) => (this.openedFrom = source));

		AOS.init({mirror: true});
		this.tooltipService.attach();
		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
			if ('fbq' in window) {
				(window as any).fbq('track', 'PageView');
			}
		});

		this.accountStatus$.subscribe((accountStatus) => {
			if (accountStatus === AccountStatus.OPEN) {
				this.store.dispatch(new Auth.User.GetDetails());
			}
		});
	}

	openDialog() {
		this.inviteCode$.pipe(first()).subscribe(
			(inviteCode) =>
				!inviteCode &&
				this.matDialog.open(InviteCodeComponent, {
					width: '480px',
				}),
		);
	}

	openSearchDialog() {
		this.matDialog.open(SearchComponent, DEFAULT_ASSETS_DIALOG_CONFIG);
	}
}
