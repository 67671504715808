import {Directive, ElementRef, Renderer2} from '@angular/core';

@Directive({
	selector: 'img[savvyPreloadImage]',
})
export class PreloadImageDirective {
	private withError = false;

	constructor(private elementRef: ElementRef<HTMLImageElement>, private renderer: Renderer2) {
		this.elementRef.nativeElement.onload = this.onLoadSuccess.bind(this);
		this.elementRef.nativeElement.onerror = this.onLoadError.bind(this);
	}

	onLoadSuccess() {
		if (this.withError) {
			this.withError = false;
			return;
		}
	}

	onLoadError() {
		this.withError = true;
		this.elementRef.nativeElement.src = 'assets/logo-blank.svg';
	}
}
