import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Action, createSelector, Selector, State, StateContext} from '@ngxs/store';
import {tap} from 'rxjs/operators';
import {handleStoreError} from '@savvy/helpers/handleStoreError';
import {EntryType, Group, Instrument, InstrumentDetails, ListEntry, Loner} from '@savvy/models/store';
import {CoreService} from '@savvy/services/core.service';
import {Core} from '@savvy/store/actions/core.actions';
import {ErrorState} from '@savvy/store/state/error.state';

export interface CoreStateModel {
	portfolio: ListEntry[];
	watchlist: ListEntry[];
	instrumentsBase: Instrument[];
	instruments: InstrumentDetails[];
	error: string | null;
}

@State<CoreStateModel>({
	name: 'core',
	defaults: {
		portfolio: [],
		watchlist: [],
		instrumentsBase: [],
		instruments: [],
		error: null,
	},
})
@Injectable()
export class CoreState extends ErrorState {
	constructor(private coreService: CoreService, private router: Router) {
		super();
	}

	static instrument(symbol: string) {
		return createSelector([CoreState], (state: CoreStateModel) =>
			state.instruments.find((item) => item.symbol === symbol),
		);
	}

	static pie(id: string) {
		return createSelector([CoreState.pies], (entries: Group[]) => entries.find((item) => item.id === id));
	}

	@Selector([CoreState])
	static instruments(state: CoreStateModel) {
		return state.instrumentsBase;
	}

	@Selector([CoreState])
	static pies(state: CoreStateModel) {
		return [...state.watchlist, ...state.portfolio]
			.filter((item) => item.type === EntryType.Group)
			.map(({data}) => data) as Group[];
	}

	@Selector([CoreState])
	static portfolio(state: CoreStateModel) {
		return state.portfolio;
	}

	@Selector([CoreState])
	static watchlist(state: CoreStateModel) {
		return state.watchlist;
	}

	@Selector([CoreState])
	static portfolioLoners(state: CoreStateModel) {
		return state.portfolio
			.filter((item) => item.type === EntryType.Loner)
			.map((item) => (item.data as Loner).symbol);
	}

	@Selector([CoreState])
	static watchlistLoners(state: CoreStateModel) {
		return state.watchlist
			.filter((item) => item.type === EntryType.Loner)
			.map((item) => (item.data as Loner).symbol);
	}

	@Action(Core.GetPortfolio)
	getPortfolio(ctx: StateContext<CoreStateModel>) {
		return this.coreService.getNewPortfolio().pipe(
			tap(({portfolio, watchlist}) => ctx.patchState({portfolio, watchlist})),
			handleStoreError(ctx),
		);
	}

	@Action(Core.GetInstruments)
	getInstruments(ctx: StateContext<CoreStateModel>) {
		return this.coreService.getInstruments().pipe(
			tap((instrumentsBase) => ctx.patchState({instrumentsBase})),
			handleStoreError(ctx),
		);
	}

	@Action(Core.GetInstrumentDetails)
	getInstrumentDetails(ctx: StateContext<CoreStateModel>, action: Core.GetInstrumentDetails) {
		const state = ctx.getState();
		if (state.instruments.some((item) => item.symbol === action.symbol)) {
			return;
		}

		return this.coreService.getInstrumentDetails(action.symbol).pipe(
			tap((instrument) =>
				ctx.setState((existing) => ({
					...existing,
					instruments: [...existing.instruments, instrument],
				})),
			),
			handleStoreError(ctx),
		);
	}

	@Action(Core.Group.Create)
	createGroup(ctx: StateContext<CoreStateModel>, action: Core.Group.Create) {
		return this.coreService.createPie(action.assets).pipe(
			tap((group) =>
				ctx.setState((existing) => ({
					...existing,
					watchlist: [{type: EntryType.Group, data: group}, ...existing.watchlist],
				})),
			),
			tap((group) => this.router.navigate(['invest', 'pie', 'watchlist', group.id])),
			handleStoreError(ctx),
		);
	}

	@Action(Core.Group.Update)
	updateGroup(ctx: StateContext<CoreStateModel>, action: Core.Group.Update) {
		return this.coreService.updateGroup(action.id, action.data).pipe(handleStoreError(ctx));
	}

	@Action(Core.Group.Delete)
	deleteGroup(ctx: StateContext<CoreStateModel>, action: Core.Group.Delete) {
		return this.coreService.deleteGroup(action.id).pipe(handleStoreError(ctx));
	}
}
