import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {Injectable} from '@angular/core';

import {TooltipMessagesComponent} from '@savvy/components/tooltip-messages/tooltip-messages.component';
import {TooltipMessage, TooltipMessageType} from '@savvy/models/tooltip.model';

@Injectable({
	providedIn: 'root',
})
export class TooltipService {
	private static instance: TooltipMessagesComponent | null = null;

	overlayRef: OverlayRef;

	constructor(private overlay: Overlay) {}

	attach(): void {
		if (TooltipService.instance) {
			return;
		}
		const positionStrategy = this.overlay.position().global().bottom('125px').right('20px');
		this.overlayRef = this.overlay.create({
			positionStrategy,
		});
		TooltipService.instance = this.overlayRef.attach(new ComponentPortal(TooltipMessagesComponent)).instance;
	}

	detach(): void {
		TooltipService.instance = null;
		this.overlayRef.detach();
	}

	showMessage(message: TooltipMessage): void {
		TooltipService.instance?.showMessage(message);
	}

	deleteMessage(type: TooltipMessageType): void {
		TooltipService.instance?.deleteMessage(type);
	}
}
