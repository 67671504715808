import {CreateSubscriptionRequestBody} from '@savvy/models/store';

export namespace Subscriptions {
	export class Load {
		static readonly type = '[Subscription] Load';
	}

	export class Create {
		static readonly type = '[Subscription] Create';

		constructor(public request: CreateSubscriptionRequestBody) {}
	}

	export class Retry {
		static readonly type = '[Subscription] Retry';

		constructor(public request: CreateSubscriptionRequestBody) {}
	}

	export class Delete {
		static readonly type = '[Subscription] Delete';
	}
}
