import {isObjectEmpty} from './isObjectEmpty';

export const filterNull = <T extends Record<string, any>>(obj: T): T => {
	const result = {...obj};
	for (const key in result) {
		const value = result[key];
		if (value === null || value === undefined) {
			delete result[key];
		} else if (typeof value === 'object' && !Array.isArray(value)) {
			if (isObjectEmpty(value)) {
				delete result[key];
			} else {
				result[key] = filterNull(value);
				if (result[key] === null) {
					delete result[key];
				}
			}
		}
	}

	if (isObjectEmpty(result)) {
		return null;
	}
	return result;
};
