import {Loner, UpdatePieRequest} from '@savvy/models/store';

export namespace Core {
	export class GetPortfolio {
		static readonly type = '[Core] Get Portfolio';
	}

	export class GetInstruments {
		static readonly type = '[Core] Get Instruments';
	}

	export class GetInstrumentDetails {
		static readonly type = '[Core] Get Instrument Details';

		constructor(public symbol: string) {}
	}

	export namespace Group {
		export class Create {
			static readonly type = '[Core] Create Group';

			constructor(public assets: Omit<Loner, 'id'>[]) {}
		}

		export class Update {
			static readonly type = '[Core] Update Group';

			constructor(public id: string, public data: UpdatePieRequest) {}
		}

		export class Delete {
			static readonly type = '[Core] Delete Group';

			constructor(public id: string) {}
		}
	}
}
