import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSortModule} from '@angular/material/sort';
import {RouterModule} from '@angular/router';

import {LazyTableModule} from '@savvy/components/lazy-table/lazy-table.module';
import {GainModule} from '@savvy/directives/gain/gain.module';
import {PreloadImageModule} from '@savvy/directives/preload-image/preload-image.module';
import {PipesModule} from '@savvy/pipes/pipes.module';
import {CheckmarkModule} from '../checkmark/checkmark.module';
import {LoaderModule} from '../loader/loader.module';

import {AssetsComponent} from './assets.component';

@NgModule({
	declarations: [AssetsComponent],
	imports: [
		CommonModule,
		RouterModule,
		// local
		GainModule,
		PipesModule,
		LoaderModule,
		CheckmarkModule,
		PreloadImageModule,
		LazyTableModule,
		// lib
		MatCardModule,
		MatSortModule,
		MatButtonModule,
		MatIconModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatInputModule,
	],
	exports: [AssetsComponent],
})
export class AssetsModule {}
