<header *ngIf="(isAuthPage$ | async) === true">
	<button class="ham" (click)="toggleMobileMenu(true)">☰</button>

	<div class="header-content">
		<a class="header__logo">
			<img src="assets/logo-haicred.svg" alt="Haicred" />
			<div class="header__logo__title"></div>
		</a>

		<ng-container *ngIf="(isAuthPage$ | async) === false">
			<nav>
				<a
					*ngFor="let link of links$ | async"
					[routerLink]="link.value"
					[class.active]="currentUrl$ | async | startsWith: link.value"
					(click)="link.click && link.click()"
					(mouseenter)="hoveringOn$.next(link.value)"
				>
					<span>{{ link.label }}</span>
				</a>
				<a href="{{ faqBaseUrl }}/faq">
					<span>Help</span>
				</a>
				<a *ngIf="(loggedIn$ | async) === false" href="{{ academyBaseUrl }}/academy">
					<span>Haicred</span>
				</a>
			</nav>

			<div *ngIf="loggedIn$ | async; else login" class="user-menu">
				<ng-container *ngIf="showFullVersion">
					<div class="search" (click)="openSearchDialog()">
						Search for stocks, SMAs, ETFs...
						<img src="assets/search.svg" alt="" />
					</div>
				</ng-container>

				<button #bell mat-icon-button color="accent" (click)="toggleNotifications()">
					<span *ngIf="unread$ | async as unread">{{ unread > 100 ? '99+' : unread }}</span>
					<img src="assets/notifications/bell.svg" />
				</button>

				<div #avatar="cdkOverlayOrigin" cdkOverlayOrigin class="header__name" (click)="toggleAccountMenu()">
					<img *ngIf="avatarUrl$ | async as avatarUrl; else textAvatar" [src]="avatarUrl" />
					<ng-template #textAvatar>
						<img src="assets/profile.svg" alt="">
					</ng-template>
				</div>

				<ng-template
					cdkConnectedOverlay
					[cdkConnectedOverlayOrigin]="avatar"
					[cdkConnectedOverlayOpen]="isMenuOverlayOpen"
					[cdkConnectedOverlayOffsetY]="15"
					[cdkConnectedOverlayOffsetX]="12"
				>
					<div #accountMenu *ngIf="user$ | async as user" class="account">
						<div class="account__signpost"></div>
						<div class="account__user">
							<p>{{ user.firstName }} {{ user.lastName }}</p>
							<span>{{ user.email }}</span>
						</div>
						<div routerLink="./subscription" class="account__upgrade-banner" (click)="closeAccountMenu()">
							<div>
								<h3>Join Haicred E-Trade</h3>
								<p>Upgrade your account to trade in stocks and ETFs and so much more.</p>
								<a>Upgrade</a>
							</div>
							<img src="assets/favicon.png" />
							<div class="ribbon"></div>
						</div>
						<a *ngFor="let link of profileLinks" [routerLink]="link.value" (click)="link.click && link.click()" (click)="closeAccountMenu()">{{
							link.label
						}}</a>
					</div>
				</ng-template>
			</div>
			<ng-template #login>
				<div class="user-menu">
					<button mat-stroked-button color="accent" routerLink="../auth/login">Login</button>
				</div>
			</ng-template>
		</ng-container>
	</div>

	<div *ngIf="(isAuthPage$ | async) === false" class="sub-menu" [class.line]="!(subLinks$ | async)?.length">
		<div>
			<a
				*ngFor="let link of subLinks$ | async"
				[class.active]="currentUrl$ | async | startsWith: link.value"
				[routerLink]="link.value"
				[savvyQrTooltip]="link.tooltip ? 'bottom' : undefined"
				[ngStyle]="{'opacity': link.opacity ? '0.5' : '1'}"
			>
				{{ link.label }}
			</a>
		</div>
	</div>
</header>

<nav [class.open]="isMobileMenuOpen" (click)="toggleMobileMenu(false)" class="mobile-menu">
	<a class="close"></a>

	<a routerLink="home" fragment="shares">Products</a>
	<a routerLink="waiting-list">Waiting List</a>
	<a href="{{ academyBaseUrl }}/academy">Haicred</a>
</nav>

<router-outlet></router-outlet>
