export enum OnboardingOptionsType {
	Marital = 'maritalOptions',
	EmploymentStatus = 'employmentStatusOptions',
	TypeOfEmployment = 'typeOfEmploymentOptions',
	EmploymentPosition = 'employmentPositionOptions',
	ImportanceOfLiquidity = 'importanceOfLiquidity',
	InvestmentExperience = 'investmentExperience',
	InvestmentHorizon = 'investmentHorizon',
	RiskTolerance = 'riskTolerance',
	AnnualIncome = 'annualIncome',
	NetIncome = 'netIncome',
}

export enum PhysicalDocumentType {
	Identification = 'identification',
	Address = 'address',
	Selfie = 'biometric',
	Disclosures = 'disclosures',
}

export enum OnboardingStep {
	PersonalInformation,
	AdditionalInformation,
	InvestmentProfile,
	Disclosures,
	InviteFriends,
}

export interface AdditionalInformationStep {
	citizenship: string;
	employedStatus: string;
	employedName: string | null;
	employedType: string | null;
	employedPosition: string | null;
	maritalStatus: string;
	maritalName: string | null;
}

export interface InvestmentProfileStep {
	annualIncome: number;
	netIncome: number;
	importanceOfLiquidity: string;
	investingExperience: string;
	investmentHorizon: string;
	riskTolerance: string;
}

export interface DisclosuresStep {
	affiliated: boolean;
	affiliatedCompanyName: string | null;
	shareholder: boolean;
	shareholderCompanyName: string | null;
	shareholderTickerSymbol: string | null;
	publicOfficial: boolean;
	publicOfficialName: string | null;
	publicOfficialMembers: string | null;
	startDateOfOfficialsPosition: Date | null;
	none: boolean;
}

export interface OnboardingData {
	addressData: {
		city: string;
		fullAddressStr: string;
		neighborhood: string;
		state: string;
		street: string;
		zip: string;
	};
	documents: {
		address: OnboardingDocumentAddress;
		biometric: OnboardingDocument;
		disclosures: OnboardingDocument;
		identification: OnboardingDocumentIdentification;
	};
	employmentData: {
		company: string;
		position: string;
		status: string;
		type: string;
	};
	identificationData: {
		citizenship: string;
		cpf: string;
		dob: string;
		firstName: string;
		lastName: string;
	};
	investmentData: {
		affiliatedCompanyName: string;
		annualIncome: number;
		importanceOfLiquidity: string;
		investmentExperience: string;
		investmentHorizon: string;
		networthTotal: number;
		officialsMembers: string;
		officialsName: string;
		riskTolerance: string;
		shareholderOrDirectorCompanyName: string;
		shareholderOrDirectorCompanySymbol: string;
		startDateOfOfficialsPosition: string;
	};
	personalData: {
		marital: string;
		partnerName: string;
		parentName?: string;
	};
}

export interface OnboardingDocument {
	status: DocumentStatus;
}

export interface OnboardingDocumentAddress extends OnboardingDocument {
	cpf: string;
	expiration: string;
	fullName: string;
}

export interface OnboardingDocumentIdentification extends OnboardingDocument {
	cpf: string;
	fullName: string;
	isBrazilian: boolean;
	mothersName: string;
}

export interface ConfirmationID {
	fullName: string;
	mothersName: string;
	dob: string;
	cpf: string;
}

export interface ConfirmationAddress {
	street: string;
	city: string;
	state: string;
	country: string;
	zip: string;
}

export enum DocumentStatus {
	NotCompleted = 'NOT_COMPLETED',
	Uploading = 'UPLOADING',
	Uploaded = 'UPLOADED',
	Verifying = 'VERIFYING',
	Verified = 'VERIFIED',
	Approved = 'APPROVED',
	// ERROR_EXTRACT_DATA,
	// ERROR_VALIDATION_SERVICE,
	// ERROR_INVALID_FILE,
	// ERROR_CPF_USED,
	// ERROR_ID_EXTRACT_CPF,
	// ERROR_ID_EXTRACT_NAME,
	// ERROR_ID_EXTRACT_DOB,
	// ERROR_ID_EXTRACT_MOTHER_NAME,
	// ERROR_BIOMETRIC_EXTRACT_DATA,
	// ERROR_BIOMETRIC_INVALID_DATA,
	// ERROR_POA_MATCH_CPF,
	// ERROR_POA_MATCH_NAME,
	// ERROR_POA_MATCH_DATA,
	// ERROR_POA_EXTRACT_CITY,
	// ERROR_POA_EXTRACT_STATE_PROVINCE,
	// ERROR_POA_EXTRACT_STREET,
	// ERROR_POA_EXTRACT_ZIP_POSTAL_CODE,
	// ERROR_POA_DOCUMENT_EXPIRED,
}

export interface ProfilePersonalInformation {
	fullName: string;
	motherName: string;
	birthDate: string;
	cpf: string;
	address: string;
	city: string;
	state: string;
	zipCode: string;
}

export interface ProfileAdditionalInformation {
	citizenship: string;
	employmentStatus: string;
	companyName: string;
	companyPerformance: string;
	office: string;
	maritalStatus: string;
	spouseName: string;
}

export interface ProfileInvestment {
	annualIncome: string;
	netIncome: string;
	importanceLiquidity: string;
	investmentExperience: string;
	investmentTerm: string;
	riskTolerance: string;
}

export interface ProfileDisclosures {
	publicOfficial: boolean;
	publicOfficialMembers: string;
	publicOfficialName: string;
	workingStockbroker: boolean;
	workingStockbrokerCompanyName: string;
	publiclyTradedShareholder: boolean;
	publiclyTradedShareholderCompanyName: string;
	publiclyTradedShareholderPurseCode: string;
	publicAgent: boolean;
	publicAgentStartDate: string;
	noAlternative: boolean;
}
