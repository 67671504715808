import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
	selector: 'savvy-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
	constructor(private dialog: MatDialog, private router: Router, private activatedRoute: ActivatedRoute) {}

	openDetails(symbol: string) {
		void this.router.navigate([], {
			relativeTo: this.activatedRoute,
			queryParams: {dPart: 'instrument', dInstrument: symbol},
			queryParamsHandling: 'merge',
		});
	}

	close() {
		this.dialog.closeAll();
	}
}
