import {
	ChangePasswordRequest,
	CreateAccountRequest,
	Credentials,
	LoginProvider,
	MFAType,
	SubscribeWaitingListRequest,
	UpdateDisclosuresRequest,
	UpdatePersonalAddressRequest,
	UpdatePersonalInfoRequest,
} from '@savvy/models/store';

export namespace Auth {
	export class Login {
		static readonly type = '[Auth] Login';

		constructor(public credentials: Credentials) {}
	}

	export class LoginWith {
		static readonly type = '[Auth] Login With';

		constructor(public type: LoginProvider, public token: string) {}
	}

	export class SignupWith {
		static readonly type = '[Auth] Signup With';

		constructor(public type: LoginProvider, public token: string) {}
	}

	export class Logout {
		static readonly type = '[Auth] Logout';
	}

	export class CheckInviteCode {
		static readonly type = '[Auth] Check Invite Code';

		constructor(public code: string) {}
	}

	export class CreateAccount {
		static readonly type = '[Auth] Create Account';

		constructor(public request: CreateAccountRequest) {}
	}

	export class UpdatePersonalInfo {
		static readonly type = '[Auth] Update Personal Info';

		constructor(public request: UpdatePersonalInfoRequest) {}
	}

	export class Disclosures {
		static readonly type = '[Auth] Update Disclosures';

		constructor(public request: UpdateDisclosuresRequest) {}
	}

	export class UpdatePersonalAddress {
		static readonly type = '[Auth] Update Personal Address';

		constructor(public request: UpdatePersonalAddressRequest) {}
	}

	export class Setup2FA {
		static readonly type = '[Auth] Setup 2FA';

		constructor(public type: MFAType, public code: string, public password: string) {}
	}

	export class Remove2FA {
		static readonly type = '[Auth] Remove 2FA';
	}

	export class ChangePassword{
		static readonly type = '[Auth] Change Password';

		constructor(public request: ChangePasswordRequest) {}
	}

	export namespace User {
		export class Get {
			static readonly type = '[User] Get';
		}

		export class GetDetails {
			static readonly type = '[User] Get Details';
		}

		export class SubscribeWaiting {
			static readonly type = '[User] Subscribe to Waiting List';

			constructor(public request: SubscribeWaitingListRequest) {}
		}

		export class GetAccount {
			static readonly type = '[User] Get Account';
		}
	}

	export namespace Check {
		export class Email {
			static readonly type = '[User] Check Email';

			constructor(public email: string, public sendEmail?: boolean) {}
		}

		export class Code {
			static readonly type = '[User] Check Code';

			constructor(public code: string, public type: string, public identification: string) {}
		}

		export class Phone {
			static readonly type = '[User] Check Phone';

			constructor(public phone: string, public skip?: boolean) {}
		}

		export class CPF {
			static readonly type = '[User] Check CPF';

			constructor(public cpf: string) {}
		}

		export class EmailChange {
			static readonly type = '[User] Check Email Change';

			constructor(public email: string) {}
		}

		export class PhoneChange {
			static readonly type = '[User] Check Phone Change';

			constructor(public phone: string) {}
		}

		export class Password {
			static readonly type = '[User] Check Password';

			constructor(public oldPassword: string) {}
		}
	}

	export namespace Account {

		export class Balance {

			static readonly type = '[Account] Balance';
			constructor(public date: string) { }
		}

		export class StatementFilter {

			static readonly type = '[Account] StatementFilter';
			constructor() { }
		}

		export class Summary {
			static readonly type = '[Account] Summary';
			constructor() { }
		}

		export class PeriodBalance {
			static readonly type = '[Account] Period Balance';
			constructor(public period: number){}
		}

		export class CreateKeyPix {
			static readonly type = '[Account] Create Key Pix';
			constructor() {}
		}

		export class SendVerificationCodePix{
			static readonly type = '[Account] Send Verification Code Pix';
			constructor(){}
		}

		export class VerificationCodePix{
			static readonly type = '[Account] Verification Code Pix';
			constructor(){}
		}

		export class DeleteKeyPix{
			static readonly type = '[Account] Delete Key Pix';
			constructor(){}
		}

		export class CreateClaimKeyPix{
			static readonly type = '[Account] Create Claim Key Pix';
			constructor(){}
		}

		export class ConfirmClaimKeyPix{
			static readonly type = '[Account] Confirm Claim Key Pix';
			constructor(){}
		}

		export class CancelClaimKeyPix{
			static readonly type = '[Account] Cancel Claim Key Pix';
			constructor(){}
		}

		export class ConsultLimitPix{
			static readonly type = '[Account] Consult Limit Pix';
			constructor(){}
		}

		export class CreateLimitPix{
			static readonly type = '[Account] Create Limit Pix';
			constructor(){}
		}

		export class UpdateLimitPix{
			static readonly type = '[Account] Update Limit Pix';
			constructor(){}
		}

		export class ListBeneficiary{
			static readonly type = '[Account] List Beneficiary';
			constructor(){}
		}

		export class DeleteBeneficiary{
			static readonly type = '[Account] Delete Beneficiary';
			constructor(){}
		}

		export class CreateBeneficiary{
			static readonly type = '[Account] Create Beneficiary';
			constructor(){}
		}

		export class UpdateBeneficiary{
			static readonly type = '[Account] Update Beneficiary';
			constructor(){}
		}

		export class GetNotifications{
			static readonly type = '[Account] Get Notifications';
			constructor(){}
		}

		export class AddNotification{
			static readonly type = '[Account] Add Notification';
			constructor(){}
		}

		export class MarkAsReadNotification{
			static readonly type = '[Account] Mark As Read Notification';
			constructor(){}
		}
	}
}
