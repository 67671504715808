import {ListRange} from '@angular/cdk/collections';
import {DataSource} from '@angular/cdk/table';
import {
	Component,
	ContentChildren,
	Directive,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	QueryList,
	Renderer2,
	TemplateRef,
} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Directive({
	selector: '[savvyColumn]',
})
export class ColumnDirective {
	constructor(private elementRef: ElementRef<HTMLElement>, private renderer: Renderer2) {
		this.renderer.addClass(this.elementRef.nativeElement, 'savvy-column');
	}
}

@Directive({
	selector: '[savvyLazyColumn]',
})
export class LazyColumnDirective {
	@Input()
	savvyLazyColumnName: string;
	@Input()
	savvyLazyColumnKey: string;

	constructor(public templateRef: TemplateRef<unknown>) {}
}

@Component({
	selector: 'savvy-lazy-table',
	templateUrl: './lazy-table.component.html',
	styleUrls: ['./lazy-table.component.scss'],
})
export class LazyTableComponent<T> implements OnInit {
	@Input()
	height: number;
	@Output()
	rowClick = new EventEmitter<T>();

	columnsToDisplay: LazyColumnDirective[];

	viewChange = new BehaviorSubject<ListRange>({start: 0, end: 0});
	data$: Observable<readonly T[]>;

	private _dataSource: DataSource<T>;
	private _displayedColumns: string[] = [];
	private _columns: QueryList<LazyColumnDirective>;

	constructor() {}

	@Input()
	set displayedColumns(value: string[]) {
		this._displayedColumns = value;
		this.updateColumnsToDisplay();
	}

	@Input()
	set dataSource(value: DataSource<T>) {
		this._dataSource?.disconnect({
			viewChange: this.viewChange,
		});

		this._dataSource = value;
		this.data$ = value.connect({
			viewChange: this.viewChange,
		});
	}

	@ContentChildren(LazyColumnDirective)
	set columns(value: QueryList<LazyColumnDirective>) {
		this._columns = value;
		this.updateColumnsToDisplay();
	}

	ngOnInit(): void {}

	updateColumnsToDisplay() {
		this.columnsToDisplay =
			this._columns
				?.filter((item) => this._displayedColumns.includes(item.savvyLazyColumnKey))
				.sort(
					(a, b) =>
						this._displayedColumns.indexOf(a.savvyLazyColumnKey) -
						this._displayedColumns.indexOf(b.savvyLazyColumnKey),
				) || [];
	}
}
