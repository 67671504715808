import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {GainDirective} from './gain.directive';

@NgModule({
	declarations: [GainDirective],
	imports: [CommonModule],
	exports: [GainDirective],
})
export class GainModule {}
