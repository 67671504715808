import {ConnectedPosition, Overlay, OverlayPositionBuilder, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {Directive, ElementRef, HostListener, OnInit, Input, Injector, ViewContainerRef} from '@angular/core';
import {QrTooltipComponent} from '@savvy/components/qr-tooltip/qr-tooltip.component';

type TooltipDirection = 'right' | 'bottom' | 'pie-right';

@Directive({
	selector: '[savvyQrTooltip]',
})
export class ToolTipDirective implements OnInit {
	public _overlayRef: OverlayRef;

	private direction: TooltipDirection;

	constructor(
		public _overlay: Overlay,
		private _overlayPositionBuilder: OverlayPositionBuilder,
		private _elementRef: ElementRef,
		private _viewContainerRef: ViewContainerRef,
	) {}

	@Input()
	set savvyQrTooltip(value: TooltipDirection) {
		this.direction = value;
	}

	@HostListener('mouseenter')
	show() {
		if (this._overlayRef && !this._overlayRef.hasAttached()) {
			const injector = Injector.create([
				{
					provide: OverlayRef,
					useValue: this._overlayRef,
				},
			]);
			this._overlayRef.attach(new ComponentPortal(QrTooltipComponent, this._viewContainerRef, injector));
			this._overlayRef.backdropClick().subscribe(() => this._overlayRef.detach());
		}
	}

	ngOnInit() {
		const positions: ConnectedPosition[] = [];

		switch (this.direction) {
			case 'right':
				positions.push({
					originX: 'end',
					originY: 'top',
					overlayX: 'start',
					overlayY: 'center',
					offsetY: 30,
					offsetX: 25,
				});
				break;
			case 'bottom':
				positions.push({
					originX: 'center',
					originY: 'bottom',
					overlayX: 'center',
					overlayY: 'top',
					offsetY: 5,
				});
				break;
			case 'pie-right':
				positions.push({
					originX: 'end',
					originY: 'top',
					overlayX: 'start',
					overlayY: 'center',
					offsetY: 30,
					offsetX: 73,
				});
				break;
			default:
				return;
		}

		const positionStrategy = this._overlayPositionBuilder
			.flexibleConnectedTo(this._elementRef)
			.withPositions(positions);

		this._overlayRef = this._overlay.create({
			positionStrategy,
			hasBackdrop: true,
			backdropClass: 'cdk-overlay-transparent-backdrop',
		});
	}
}
