import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {sum} from '@savvy/helpers/sum';
import {unwrap} from '@savvy/helpers/unwrap';
import {FirebaseCourse, QuizAnswer} from '@savvy/models/store';

@Injectable({
	providedIn: 'root',
})
export class CoursesService {
	constructor(private firestore: AngularFirestore, private functions: AngularFireFunctions) {}

	fetchCourses(): Observable<FirebaseCourse.Course[]> {
		return this.firestore
			.collection<FirebaseCourse.Course>('courses', (query) => query.where('isPublished', '==', true))
			.get()
			.pipe(
				map((query) =>
					query.docs.map((doc) => ({
						...doc.data(),
						duration: sum(...unwrap(doc.data().videos, 'duration')),
					})),
				),
			);
	}

	getCourseDetails(slug: string): Observable<FirebaseCourse.Course> {
		return this.firestore
			.collection<FirebaseCourse.Course>('courses')
			.doc(slug)
			.get()
			.pipe(
				map((doc) => ({
					...doc.data(),
					duration: sum(...unwrap(doc.data().videos, 'duration')),
				})),
			);
	}

	getVideoToken(uid: string) {
		return this.functions.httpsCallable<{uid: string}, {token: string}>('education-getSignedToken')({uid});
	}

	createReview(slug: string, rating: number, feedback: string) {
		return this.functions.httpsCallable('education-saveReview')({slug, rating, feedback});
	}

	saveQuiz(slug: string, answers: QuizAnswer[]) {
		return this.functions.httpsCallable<{slug: string; answers: QuizAnswer[]}, {score: number}>(
			'education-saveQuiz',
		)({
			slug,
			answers,
		});
	}

	getReviews(slug: string) {
		return this.firestore
			.collection('courses')
			.doc(slug)
			.collection<FirebaseCourse.Review>('reviews')
			.valueChanges();
	}
}
