import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {map, tap} from 'rxjs/operators';

import {clearStoreError, handleStoreError} from '@savvy/helpers/handleStoreError';
import {
	Account,
	AccountSummary,
	MFAType,
	PhysicalDocument,
	Profile,
	Referrals,
	UpdatedAddress,
	UpdateDisclosuresRequest,
	UpdatePersonalAddressRequest,
	UpdatePersonalInfoRequest,
	UserDetails,
} from '@savvy/models/store';
import {AccountDetails} from '@savvy/models/wallet.model';
import {AuthService} from '@savvy/services/auth.service';
import {WalletService} from '@savvy/services/wallet.service';
import {Auth} from '@savvy/store/actions/auth.actions';
import {ErrorState} from '@savvy/store/state/error.state';
import { AccountService } from '@savvy/services/account.service';
import { AddNotification, CancelClaimKeyPix, ConfirmClaimKeyPix, CreateBeneficiary, CreateClaimKeyPix, CreateKeyPix, CreateLimitPix, DeleteBeneficiary, DeleteKeyPix, MarkAsReadNotification, SendVerificationCodePix, StatementFilter, UpdateBeneficiary, UpdateLimitPix, VerificationCodePix } from '@savvy/models/store/account';

export interface AuthStateModel {
	user: Profile | null;
	userDetails: UserDetails | null;
	account: Account | null;
	accountBalance: any;
	accountSummary: AccountSummary | null;
	physicalDocuments: PhysicalDocument[] | null;
	error: string | null;
	mfaType: MFAType | null;
	token: string | null;
	firebaseToken: string | null;
	updatedAddress: UpdatedAddress;
	referral: Referrals;
	accountDetails: AccountDetails | null;

	step: string | null;
	accountStatus: string | null;
	emailToken: string | null;
	inviteCode: string | null;
	backupCodes: string[];
	signUpToken: string | null;
}

const getInitialState = (): AuthStateModel => ({
	account: null,
	userDetails: null,
	accountSummary: null,
	physicalDocuments: null,
	user: null,
	error: null,
	mfaType: null,
	token: null,
	firebaseToken: null,
	updatedAddress: null,
	referral: null,
	accountDetails: null,
	accountBalance: null,
	step: null,
	accountStatus: null,
	emailToken: null,
	inviteCode: null,
	backupCodes: [],
	signUpToken: null,
});

@State<AuthStateModel>({
	name: 'auth',
	defaults: getInitialState(),
})
@Injectable()
export class AuthState extends ErrorState {
	constructor(private authService: AuthService, private walletService: WalletService, private accountService: AccountService) {
		super();
	}

	@Selector([AuthState])
	static user(state: AuthStateModel) {
		return state.user;
	}

	@Selector([AuthState])
	static userDetails(state: AuthStateModel) {
		return state.userDetails;
	}

	@Selector([AuthState])
	static accountBalance(state: AuthStateModel) {
		return state.accountBalance;
	}

	@Selector([AuthState])
	static referral(state: AuthStateModel) {
		return state.referral;
	}

	@Selector([AuthState])
	static accountSummary(state: AuthStateModel) {
		return state.accountSummary;
	}

	@Selector([AuthState])
	static accountDetails(state: AuthStateModel) {
		return state.accountDetails;
	}

	@Selector([AuthState])
	static accountNumber(state: AuthStateModel) {
		return state.account.accountNo;
	}

	@Selector([AuthState])
	static isLoggedIn(state: AuthStateModel) {
		return !!state.token;
	}

	@Selector([AuthState])
	static inviteCode(state: AuthStateModel) {
		return state.inviteCode;
	}

	@Selector([AuthState])
	static token(state: AuthStateModel) {
		// This is the only selector that is called before the state is initialised
		return state?.token;
	}

	@Selector([AuthState])
	static mfaType(state: AuthStateModel) {
		return state.mfaType;
	}

	@Selector([AuthState.user, AuthState.mfaType])
	static mfaRequired(user: Profile | null, mfaType: MFAType | null) {
		return !!(!user && mfaType);
	}

	@Selector([AuthState])
	static updatedAddress(state: AuthStateModel) {
		return state.updatedAddress;
	}

	@Selector([AuthState])
	static backupCodes(state: AuthStateModel) {
		return state.backupCodes;
	}

	@Action(Auth.Login)
	login(ctx: StateContext<AuthStateModel>, action: Auth.Login) {
		clearStoreError(ctx);
		return this.authService.login(action.credentials, {force2FA: true}).pipe(
			tap((user) => ctx.patchState(user)),
			handleStoreError(ctx),
		);
	}

	@Action(Auth.LoginWith)
	loginWith(ctx: StateContext<AuthStateModel>, action: Auth.LoginWith) {
		clearStoreError(ctx);
		return this.authService.loginWith(action.type, action.token).pipe(
			tap((user) => ctx.patchState(user)),
			handleStoreError(ctx),
		);
	}

	@Action(Auth.SignupWith)
	signupWith(ctx: StateContext<AuthStateModel>, action: Auth.SignupWith) {
		clearStoreError(ctx);
		return this.authService.signupWith(action.type, action.token).pipe(
			tap((user) => ctx.patchState(user)),
			handleStoreError(ctx),
		);
	}

	@Action(Auth.Logout)
	logout(ctx: StateContext<AuthStateModel>) {
		ctx.setState(getInitialState());
	}

	@Action(Auth.CheckInviteCode)
	checkInviteCode(ctx: StateContext<AuthStateModel>, action: Auth.CheckInviteCode) {
		return this.authService.checkInviteCode(action.code).pipe(
			tap(() => ctx.patchState({inviteCode: action.code})),
			handleStoreError(ctx),
		);
	}

	@Action(Auth.CreateAccount)
	createAccount(ctx: StateContext<AuthStateModel>, action: Auth.CreateAccount) {
		clearStoreError(ctx);
		const state = ctx.getState();
		const formBase = {
			fullname: undefined as string,
			birthDate: undefined as string,
			cpf: undefined as string,
			email: undefined as string,
			password: undefined as string,
			signUpToken: undefined as string,
			phone: action.request.phone
				.replace(/[\D]/g, '')
				.replace('55', '')
				.replace('(', '')
				.replace(')', '')
				.replace('-', ''),
			inviteCode: state.inviteCode || action.request.inviteCode || null,
			agreements: {
				termsOfUse: true,
				privacyPolicy: true,
				customerAgreement: true,
				w8Agreement: true,
			},
		};

		if (state.signUpToken && !(action.request.email || action.request.password)) {
			formBase.signUpToken = state.signUpToken;
		} else {
			formBase.fullname = action.request.fullname;
			formBase.birthDate = action.request.birthDate;
			formBase.cpf = action.request.cpf;
			formBase.email = action.request.email;
			formBase.password = action.request.password;
		}

		return this.authService.createAccount(formBase).pipe(
			map((user) => ctx.patchState(user)),
			handleStoreError(ctx),
		);
	}

	@Action(Auth.UpdatePersonalInfo)
	updatePersonalInfo(ctx: StateContext<AuthStateModel>, action: Auth.UpdatePersonalInfo) {
		clearStoreError(ctx);

		const formBase: UpdatePersonalInfoRequest = {
			documentNumber: action.request.documentNumber,
			issuingBody: action.request.issuingBody,
			stateIssuingDocument: action.request.stateIssuingDocument,
			dateIssuedDocument: action.request.dateIssuedDocument,
			nameMother: action.request.nameMother,
			gender: action.request.gender,
			birthCountry: action.request.birthCountry,
			birthState: action.request.birthState,
			cityBirth: action.request.cityBirth,
			birthStateForeigner: action.request.birthStateForeigner,
			cityBirthForeigner: action.request.cityBirthForeigner,
			nationality: action.request.nationality,
		};

		return this.authService.updatePersonalInfo(formBase).pipe(handleStoreError(ctx));
	}

	@Action(Auth.UpdatePersonalAddress)
	updatePersonalAddress(ctx: StateContext<AuthStateModel>, action: Auth.UpdatePersonalAddress){
		clearStoreError(ctx);

		const formBase: UpdatePersonalAddressRequest ={
			zip: action.request.zip,
			address: action.request.address,
			number: action.request.number,
			neighborhood: action.request.neighborhood,
			country: action.request.country,
			state: action.request.state,
			city: action.request.city,
		}

		return this.authService.updatePersonalAddress(formBase).pipe(handleStoreError(ctx));
	}

	@Action(Auth.Setup2FA)
	setup2FA(ctx: StateContext<AuthStateModel>, action: Auth.Setup2FA) {
		clearStoreError(ctx);
		return this.authService.setup2FACode(action.code, action.password, action.type).pipe(
			tap(({backupCodes}) => ctx.patchState({mfaType: action.type, backupCodes})),
			handleStoreError(ctx),
		);
	}

	@Action(Auth.Remove2FA)
	remove2FA(ctx: StateContext<AuthStateModel>) {
		clearStoreError(ctx);
		return this.authService.delete2Fa().pipe(
			tap(() => ctx.patchState({mfaType: null})),
			handleStoreError(ctx),
		);
	}

	@Action(Auth.User.Get)
	getUser(ctx: StateContext<AuthStateModel>) {
		return this.authService.getUser().pipe(
			tap((user) => ctx.patchState({...user})),
			handleStoreError(ctx),
		);
	}

	@Action(Auth.User.GetDetails)
	getUserDetails(ctx: StateContext<AuthStateModel>) {
		return this.authService.getUserDetails().pipe(
			tap((userDetails) => ctx.patchState({userDetails})),
			handleStoreError(ctx),
		);
	}

	@Action(Auth.User.SubscribeWaiting)
	subscribeToWaiting(ctx: StateContext<AuthStateModel>, action: Auth.User.SubscribeWaiting) {
		return this.authService.subscribeToWaitingList(action.request).pipe(handleStoreError(ctx));
	}

	@Action(Auth.User.GetAccount)
	getAccount(ctx: StateContext<AuthStateModel>) {
		return this.walletService.getAccountDetails().pipe(
			tap((accountDetails) => ctx.patchState({accountDetails})),
			handleStoreError(ctx),
		);
	}

	@Action(Auth.Check.Email)
	checkEmail(ctx: StateContext<AuthStateModel>, action: Auth.Check.Email) {
		clearStoreError(ctx);
		return this.authService.checkEmail(action.email, action.sendEmail).pipe(handleStoreError(ctx));
	}

	@Action(Auth.Check.EmailChange)
	checkEmailChange(ctx: StateContext<AuthStateModel>, action: Auth.Check.EmailChange) {
		clearStoreError(ctx);
		return this.authService.checkEmailChange(action.email).pipe(handleStoreError(ctx));
	}

	@Action(Auth.Check.CPF)
	checkCPF(ctx: StateContext<AuthStateModel>, action: Auth.Check.CPF) {
		clearStoreError(ctx);
		return this.authService.checkCPF(action.cpf);
	}

	@Action(Auth.Check.Phone)
	checkPhone(ctx: StateContext<AuthStateModel>, action: Auth.Check.Phone) {
		clearStoreError(ctx);
		return this.authService
			.checkPhone(
				action.phone.replace(/\D/g, '').replace('55', '').replace('(', '').replace(')', '').replace('-', ''),
				action.skip,
			)
			.pipe(handleStoreError(ctx));
	}

	@Action(Auth.Check.PhoneChange)
	checkPhoneChange(ctx: StateContext<AuthStateModel>, action: Auth.Check.PhoneChange) {
		clearStoreError(ctx);
		return this.authService
			.checkPhoneChange(
				action.phone.replace(/\D/g, '').replace('55', '').replace('(', '').replace(')', '').replace('-', '')
			)
			.pipe(handleStoreError(ctx));
	}

	@Action(Auth.Check.Code)
	checkEmailCode(ctx: StateContext<AuthStateModel>, action: Auth.Check.Code) {
		clearStoreError(ctx);
		return this.authService.checkVerificationCode(action.code, action.type, action.identification).pipe(
			tap(({token}) => ctx.patchState({emailToken: token})),
			handleStoreError(ctx),
		);
	}

	@Action(Auth.Disclosures)
	disclosures(ctx: StateContext<AuthStateModel>, action: Auth.Disclosures) {
		clearStoreError(ctx);
		const formBase: UpdateDisclosuresRequest = {...action.request};

		return this.authService.disclosures(formBase).pipe(
			tap({}),
			handleStoreError(ctx),
		);
	}

	@Action(Auth.Account.Balance)
	AccountBalance(action: Auth.Account.Balance) {
		return this.accountService.getBalance(action.date);
	}

	@Action(Auth.Account.StatementFilter)
	StatementFilter(statementFilter: StatementFilter) {
		return this.accountService.getStatementFilter(statementFilter);
	}

	@Action(Auth.Account.Summary)
	Summary(){
		return this.accountService.getSummary();
	}

	@Action(Auth.Account.PeriodBalance)
	PeriodBalance(action: Auth.Account.PeriodBalance){
		return this.accountService.getPeriodBalance(action.period);
	}

	@Action(Auth.ChangePassword)
	ChangePassword(action: Auth.ChangePassword){
		return this.authService.changePassword(action.request);
	}

	@Action(Auth.Account.CreateKeyPix)
	CreateKeyPix(createKeyPix: CreateKeyPix){
		return this.accountService.createKeyPix(createKeyPix);
	}

	@Action(Auth.Account.SendVerificationCodePix)
	SendVerificationCodePix(sendVerifcationCodePix: SendVerificationCodePix){
		return this.accountService.sendVerificationCodePix(sendVerifcationCodePix);
	}

	@Action(Auth.Account.VerificationCodePix)
	VerificationCodePix(verificationCodePix: VerificationCodePix){
		return this.accountService.verificationCodePix(verificationCodePix);
	}

	@Action(Auth.Account.DeleteKeyPix)
	DeleteKeyPix(deleteKeyPix: DeleteKeyPix){
		return this.accountService.deleteKeyPix(deleteKeyPix);
	}

	@Action(Auth.Account.CreateClaimKeyPix)
	CreateClaimKeyPix(createClaimKeyPix: CreateClaimKeyPix){
		return this.accountService.createClaimKeyPix(createClaimKeyPix);
	}

	@Action(Auth.Account.ConfirmClaimKeyPix)
	ConfirmClaimKeyPix(confirmClaimKeyPix: ConfirmClaimKeyPix){
		return this.accountService.confirmClaimKeyPix(confirmClaimKeyPix);
	}

	@Action(Auth.Account.CancelClaimKeyPix)
	CancelClaimKeyPix(cancelClaimKeyPix: CancelClaimKeyPix){
		return this.accountService.cancelClaimKeyPix(cancelClaimKeyPix);
	}

	@Action(Auth.Account.ConsultLimitPix)
	ConsultLimitPix(){
		return this.accountService.consultLimitPix();
	}

	@Action(Auth.Account.CreateLimitPix)
	CreateLimitPix(body: CreateLimitPix){
		return this.accountService.createLimitPix(body);
	}

	@Action(Auth.Account.UpdateLimitPix)
	UpdateLimitPix(body: UpdateLimitPix){
		return this.accountService.updateLimitPix(body);
	}

	@Action(Auth.Account.ListBeneficiary)
	ListBeneficiary(){
		return this.accountService.listBeneficiary();
	}

	@Action(Auth.Account.DeleteBeneficiary)
	DeleteBeneficiary(body: DeleteBeneficiary){
		return this.accountService.deleteBeneficiary(body);
	}

	@Action(Auth.Account.CreateBeneficiary)
	CreateBeneficiary(body: CreateBeneficiary){
		return this.accountService.createBeneficiary(body);
	}

	@Action(Auth.Account.UpdateBeneficiary)
	UpdateBeneficiary(body: UpdateBeneficiary){
		return this.accountService.updateBeneficiary(body);

	}
	@Action(Auth.Account.GetNotifications)
	GetNotifications(){
		return this.accountService.getNotifications();
	}

	@Action(Auth.Account.AddNotification)
	AddNotification(body: AddNotification){
		return this.accountService.addNotification(body);
	}

	@Action(Auth.Account.MarkAsReadNotification)
	MarkAsReadNotification(body: MarkAsReadNotification){
		return this.accountService.markAsReadNotification(body);
	}
}
