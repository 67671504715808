import {Injectable} from '@angular/core';
import {AngularFireRemoteConfig} from '@angular/fire/compat/remote-config';
import {map, skipWhile} from 'rxjs/operators';
import {OnboardingOptionsType} from '@savvy/models/onboarding.model';
import {Country, DocumentStatus, ErrorMessages, ExchangeError, OnboardingCountry} from '@savvy/models/remoteConfig.model';

@Injectable({
	providedIn: 'root',
})
export class RemoteConfigService {
	countries$ = this.getObject<Country[]>('countries').pipe(skipWhile((value) => !value));

	allowedCountries$ = this.getObject<Country[]>('allowedCountries').pipe(skipWhile((value) => !value));

	onboardingCitizenshipCountries$ = this.getObject<OnboardingCountry[]>('citizenshipCountries').pipe(skipWhile((value) => !value));

	documentStatuses$ = this.getObject<DocumentStatus[]>('documentStatuses').pipe(skipWhile((value) => !value));

	exchangeErrors$ = this.getObject<ExchangeError[]>('exchangeErrors').pipe(skipWhile((value) => !value));

	errorMessages$ = this.getObject<ErrorMessages>('errorMessages').pipe(skipWhile((value) => !value));

	constructor(private remoteConfig: AngularFireRemoteConfig) {
		this.remoteConfig
			.fetchAndActivate()
			.then(() => console.log('[Remote Config] Successfully Activated'))
			.catch(() => console.log('[Remote Config] Fetching Error'));
	}

	getObject<T extends Record<string, any>>(key: string) {
		return this.getString(key).pipe(map((value) => (value ? (JSON.parse(value) as T) : (value as undefined))));
	}

	getString(key: string) {
		return this.remoteConfig.strings[key];
	}

	getBoolean(key: string) {
		return this.remoteConfig.booleans[key];
	}

	getNumber(key: string) {
		return this.remoteConfig.numbers[key];
	}

	getOnboardingOptions(type: OnboardingOptionsType) {
		return this.getObject<LabelValuePair<string, string>>(type);
	}

	alpha3to2(code: string) {
		return this.countries$.pipe(map((value) => value.find((item) => item.alpha3 === code).alpha2));
	}
}
