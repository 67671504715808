import {Observable} from 'rxjs';
import {PriceData} from './stream';

export interface Asset {
	id: string;
	instrumentSymbol: string;
	quantity: number;
}

export enum OrderType {
	Market = 'MARKET',
	Stop = 'STOP',
	Limit = 'LIMIT',
	MarketIfTouched = 'MARKET_IF_TOUCHED',
}

export enum OrderSide {
	Buy = 'BUY',
	Sell = 'SELL',
}

export enum OrderStatus {
	New = 'NEW',
	Canceled = 'CANCELED',
	Rejected = 'REJECTED',
	PartialFill = 'PARTIAL_FILL',
	Filled = 'FILLED',
}

export interface Order extends Asset {
	type: OrderType;
	side: OrderSide;
	status: OrderStatus;
}

export interface Loner {
	id: string;
	symbol: string;
	quantity: number;
}

export interface Group {
	id: string;
	name: string;
	description: string;
	locked: boolean;
	isGift: boolean;
	assets: Loner[];
}

export interface GroupLoners {
	created: Loner[];
	deleted: Loner[];
}

export type GeneralisedEntry = (Group & {type: EntryType.Group}) | (Loner & {type: EntryType.Loner});

export enum EntryType {
	Group,
	Loner,
}

export interface GroupsResponse {
	groups: Group[];
	loners: Loner[];
}

export type ListEntry =
	| {
			type: EntryType.Group;
			data: Group;
	  }
	| {
			type: EntryType.Loner;
			data: Loner;
	  };

export interface Pie {
	id: string;
	name: string;
	description: string;
	assets: Asset[];
	orders: Order[];
}

export enum PieType {
	Portfolio = 'portfolio',
	Watchlist = 'watchlist',
	Pending = 'pending',
}

export interface DisplayPie {
	id: string;
	name: string;
	description: string;
	type: PieType;
	assets: Asset[];
}

export interface DisplayPieSlice {
	entry: GeneralisedEntry;
	colour: string;
	link: {path?: string; query?: Record<string, unknown>};
	price$: Observable<PriceData>;
}

export interface Instrument {
	id: string;
	name: string;
	status: 'ACTIVE' | 'INACTIVE' | 'CLOSE_ONLY';
	symbol: string;
	isTop?: boolean;
	categories?: {
		id: string;
	}[];
}

export interface DisplayAsset {
	id: string;
	name: string;
	symbol: string;
	image: string;
	price$: Observable<PriceData>;
	details$: Observable<{peRatio: number; yield: number}>;
	isInWatchlist$: Observable<boolean>;
	isInPortfolio$: Observable<boolean>;
}

export interface Categories {
	id: string;
	description: string;
	image: string;
	name: string;
	name_localised: {
		en_US: string;
		pt_BR: string;
	};
}

export interface InstrumentDetails extends Instrument {
	type: string;
	exchange: string;
	url: string;
	closePrior: number;
	image: string;
	description: string;
	reutersPrimaryRic: string;
	sector: string;
	longOnly: string;
	orderSizeMax: number;
	orderSizeMin: number;
	orderSizeStep: number;
	exchangeNickelSpread: boolean;
	close: number;
	descriptionChinese: string;
	fundamentalDataModel: {
		instrumentID: string;
		symbol: string;
		companyName: string;
		openPrice: number;
		bidPrice: number;
		askPrice: number;
		lowPrice: number;
		highPrice: number;
		fiftyTwoWeekLowPrice: number;
		fiftyTwoWeekHighPrice: number;
		cumulativeVolume: number;
		marketCap: number;
		peRatio: number;
		dividendYield: number;
		earningsPerShare: number;
		dividend: number;
		sharesOutstanding: number;
		timeLastUpdate: string;
		bookValuePerShare: string;
		cashFlowPerShare: string;
		operatingIncome: string;
		pbRatio: string;
		volumeMovingAverage10Day: number;
		volumeMovingAverage25Day: number;
		volumeMovingAverage50Day: number;
		priceMovingAverage150Day: number;
		priceMovingAverage200Day: number;
		roe: string;
	};
}

export interface DisplayInstrument {
	// todo: id is instrumentId while assetId is the id of the asset inside of the pie
	id: string;
	assetId: string;
	name: string;
	description: string;
	image: string;
	symbol: string;
	quantity: number;
	price: number;
	cost: number;
}

export enum ChartCompression {
	Day = 0,
	Min = 1,
	Min5 = 4,
	Min30 = 8,
	Hour = 9,
	Week = 10,
}

export interface InstrumentChartEntry {
	data: string;
	open: string;
	high: string;
	low: string;
	close: string;
	volume: string;
}

export interface InstrumentChart {
	id: string;
	chart: InstrumentChartEntry[];
}

export interface UpdatePieRequest {
	name?: string;
	description?: string;
}

export interface Statements {
	monthlyStatements: Array<{
		displayName: string;
		fileKey: string;
	}>;
	taxDocuments: Array<{
		displayName: string;
		fileKey: string;
	}>;
	tradeConfirmations: Array<{
		displayName: string;
		fileKey: string;
	}>;
	vets: Array<{
		displayName: string;
		fileKey: string;
	}>;
}
