import {Injectable} from '@angular/core';
import {Action, createSelector, getActionTypeFromInstance, Selector, State, StateContext} from '@ngxs/store';

import {
	ChartDataEntry,
	ChartPeriod,
	MarketStatusData,
	PriceData,
	PriceSubscribeType,
	RefreshEventType,
	SocketEventType,
} from '@savvy/models/store/stream';
import {StreamService} from '@savvy/services/stream.service';
import {Auth} from '@savvy/store/actions/auth.actions';
import {Core} from '@savvy/store/actions/core.actions';
import {Stream} from '@savvy/store/actions/stream.actions';

export interface StreamStateModel {
	fxRate: string;
	prices: Record<string, PriceData>;
	charts: Record<string, ChartDataEntry[]>;
	marketStatus: MarketStatusData | null;
}

@State({
	name: 'stream',
	defaults: {
		fxRate: '',
		prices: {},
		charts: {},
		marketStatus: null,
	},
})
@Injectable()
export class StreamState {
	constructor(private streamService: StreamService) {}

	static priceFor(type: PriceSubscribeType, id: string | null) {
		return createSelector([StreamState], (state: StreamStateModel) => state.prices[type + (id || null)]);
	}

	static chartFor(type: PriceSubscribeType, period: ChartPeriod, id: string | null) {
		return createSelector([StreamState], (state: StreamStateModel) => state.charts[type + (id || null) + period]);
	}

	@Selector([StreamState])
	static fx(state: StreamStateModel) {
		return state.fxRate;
	}

	@Selector([StreamState])
	static marketStatus(state: StreamStateModel) {
		return state.marketStatus;
	}

	@Action(Stream.Auth)
	auth(ctx: StateContext<StreamStateModel>, action: Stream.Auth) {
		this.streamService.sendEvent({
			e: SocketEventType.Auth,
			p: {
				token: action.token,
			},
		});
	}

	@Action(Stream.SaveEvent)
	saveEvent(ctx: StateContext<StreamStateModel>, action: Stream.SaveEvent) {
		switch (action.event.e) {
			case SocketEventType.FxRate:
				ctx.patchState({
					fxRate: action.event.p.fxRate,
				});
				break;
			case SocketEventType.Price:
				ctx.patchState({
					prices: {
						...ctx.getState().prices,
						[action.event.p.type + action.event.p.id]: action.event.p.data,
					},
				});
				break;
			case SocketEventType.Chart:
				ctx.patchState({
					charts: {
						...ctx.getState().charts,
						[action.event.p.type + (action.event.p.id || null) + action.event.p.period]:
							action.event.p.data,
					},
				});
				break;
			case SocketEventType.MarketStatus:
				ctx.patchState({
					marketStatus: {
						status: action.event.p.status,
						msUntilNextStatus: action.event.p.msUntilNextStatus,
						nextStatus: action.event.p.nextStatus,
					},
				});
				break;
			case SocketEventType.Refresh:
				switch (action.event.p.type) {
					case RefreshEventType.Portfolio:
					case RefreshEventType.Watchlist:
						ctx.dispatch(new Core.GetPortfolio());
						break;
					case RefreshEventType.Holdings:
					case RefreshEventType.Cash:
						ctx.dispatch(new Auth.User.Get());
						break;
					case RefreshEventType.Activity:
						// TODO: Refresh active days
						break;
					case RefreshEventType.User:
						ctx.dispatch(new Auth.User.Get());
						ctx.dispatch(new Auth.User.GetDetails());
						break;
				}
				break;
			default:
				console.warn(`[Stream] No handler for event: ${action.event.e}`);
		}
	}

	@Action(Stream.Subscribe.Fx)
	@Action(Stream.Subscribe.MarketStatus)
	@Action(Stream.Unsubscribe.Fx)
	@Action(Stream.Unsubscribe.MarketStatus)
	subscribeSimple(
		ctx: StateContext<StreamStateModel>,
		action:
			| Stream.Subscribe.Fx
			| Stream.Subscribe.MarketStatus
			| Stream.Unsubscribe.Fx
			| Stream.Unsubscribe.MarketStatus,
	) {
		const socketEvent = {
			[Stream.Subscribe.Fx.type]: SocketEventType.FxSubscribe as const,
			[Stream.Subscribe.MarketStatus.type]: SocketEventType.MarketStatusSubscribe as const,
			[Stream.Unsubscribe.Fx.type]: SocketEventType.FxUnsubscribe as const,
			[Stream.Unsubscribe.MarketStatus.type]: SocketEventType.MarketStatusUnsubscribe as const,
		}[getActionTypeFromInstance(action)];

		this.streamService.sendEvent({
			e: socketEvent,
			p: null,
		});
	}

	@Action(Stream.Subscribe.Price)
	@Action(Stream.Unsubscribe.Price)
	subscribePrice(ctx: StateContext<StreamStateModel>, action: Stream.Subscribe.Price | Stream.Unsubscribe.Price) {
		const socketEvent = {
			[Stream.Subscribe.Price.type]: SocketEventType.PriceSubscribe as const,
			[Stream.Unsubscribe.Price.type]: SocketEventType.PriceUnsubscribe as const,
		}[getActionTypeFromInstance(action)];

		this.streamService.sendEvent({
			e: socketEvent,
			p: {
				type: action.type,
				id: action.id || undefined,
			},
		});
	}

	@Action(Stream.Subscribe.Chart)
	@Action(Stream.Unsubscribe.Chart)
	subscribeChart(ctx: StateContext<StreamStateModel>, action: Stream.Subscribe.Chart | Stream.Unsubscribe.Chart) {
		const socketEvent = {
			[Stream.Subscribe.Chart.type]: SocketEventType.ChartSubscribe as const,
			[Stream.Unsubscribe.Chart.type]: SocketEventType.ChartUnsubscribe as const,
		}[getActionTypeFromInstance(action)];

		this.streamService.sendEvent({
			e: socketEvent,
			p: {
				type: action.type,
				period: action.period,
				id: action.id || undefined,
			},
		});
	}
}
