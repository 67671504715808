import {animate, style, transition, trigger} from '@angular/animations';
import {OverlayRef} from '@angular/cdk/overlay';
import {Component, HostBinding, Input} from '@angular/core';

@Component({
	selector: 'savvy-qr-tooltip',
	templateUrl: './qr-tooltip.component.html',
	styleUrls: ['./qr-tooltip.component.scss'],
	animations: [
		trigger('hostAnimation', [
			transition(':enter', [
				style({transform: 'scale(0.95)', opacity: '0'}),
				animate(
					'300ms cubic-bezier(.5,.3,.4, 1)',
					style({
						transform: 'scale(1)',
						opacity: '1',
					}),
				),
			]),
			transition(':leave', [
				animate(
					'300ms cubic-bezier(.5,.3,.4, 1)',
					style({
						transform: 'scale(1.1)',
						opacity: '0',
					}),
				),
			]),
		]),
	],
})
export class QrTooltipComponent {
	@HostBinding('@hostAnimation')
	hostAnimation = true;

	constructor(private _overlayRef: OverlayRef) {}

	close() {
		this._overlayRef.detach();
	}
}
