import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'map'
})
export class MapPipe implements PipeTransform {

	transform<T extends Record<string, any>, K extends keyof T>(value: T[], key: K): T[K][] {
		return value?.map((item) => item[key]);
	}

}
