import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuard, UserType} from './guards/auth.guard';
import {MobileGuard} from './guards/mobile.guard';

const routes: Routes = [
	{
		path: 'pix-copia-cola',
		loadChildren: () => import('./views/auth-container/balance/pix-copia-cola/pix-copia-cola.module').then((m) => m.PixCopiaColaModule),
	},
	{
		path: 'pix-copia-cola-comprovante',
		loadChildren: () => import('./views/auth-container/balance/pix-copia-cola-comprovante/pix-copia-cola-comprovante.module').then((m) => m.PixCopiaColaComprovanteModule),
	},
	{
		path: 'pix-cobrar',
		loadChildren: () => import('./views/auth-container/balance/pix-cobrar/pix-cobrar.module').then((m) => m.PixCobrarModule),
	},
	{
		path: 'pix-cobrar-qrcode',
		loadChildren: () => import('./views/auth-container/balance/pix-cobrar-qrcode/pix-cobrar-qrcode.module').then((m) => m.PixCobrarQrcodeModule),
	},
	{
		path: 'transferir-chave-pix-ag-cc',
		loadChildren: () => import('./views/auth-container/balance/transferir-chave-pix-ag-cc/transferir-chave-pix-ag-cc.module').then((m) => m.TransferirChavePixAgCcModule),
	},
	{
		path: 'transferir-chave-pix-ag-cc-comprovante',
		loadChildren: () => import('./views/auth-container/balance/transferir-chave-pix-ag-cc-comprovante/transferir-chave-pix-ag-cc-comprovante.module').then((m) => m.TransferirChavePixAgCcComprovanteModule),
	},
	{
		path: 'transferir-chave-pix',
		canLoad: [MobileGuard, AuthGuard],
		data: {role: [UserType.USER], fallbackUrl: '/auth/login'},
		loadChildren: () => import('./views/auth-container/balance/transferir-chave-pix/transferir-chave-pix.module').then((m) => m.TransferirChavePixModule),
	},
	{
		path: 'transferir-chave-pix-comprovante',
		canLoad: [MobileGuard, AuthGuard],
		data: {role: [UserType.USER], fallbackUrl: '/auth/login'},
		loadChildren: () => import('./views/auth-container/balance/transferir-chave-pix-comprovante/transferir-chave-pix-comprovante.module').then((m) => m.TransferirChavePixComprovanteModule),
	},
	{
		path: 'pix',
		canLoad: [MobileGuard, AuthGuard],
		data: {role: [UserType.USER], fallbackUrl: '/auth/login'},
		loadChildren: () => import('./views/auth-container/balance/pix/pix.module').then((m) => m.PixModule),
	},
	{
		path: 'configuracoes',
		canLoad: [MobileGuard, AuthGuard],
		data: {role: [UserType.USER], fallbackUrl: '/auth/login'},
		loadChildren: () =>
			import('./views/auth-container/balance/configuracoes/configuracoes.module').then(
				(m) => m.ConfiguracoesModule,
			),
	},
	{
		path: 'perfil',
		canLoad: [MobileGuard, AuthGuard],
		data: {role: [UserType.USER], fallbackUrl: '/auth/login'},
		loadChildren: () => import('./views/auth-container/balance/perfil/perfil.module').then((m) => m.PerfilModule),
	},
	{
		path: 'extrato',
		canLoad: [MobileGuard, AuthGuard],
		data: {role: [UserType.USER], fallbackUrl: '/auth/login'},
		loadChildren: () =>
			import('./views/auth-container/balance/extrato/extrato.module').then((m) => m.ExtratoModule),
	},
	{
		path: 'balance',
		canLoad: [MobileGuard, AuthGuard],
		data: {role: [UserType.USER], fallbackUrl: '/auth/login'},
		loadChildren: () => import('./views/auth-container/balance/balance.module').then((m) => m.BalanceModule),
	},
	{
		path: 'auth/:screen',
		loadChildren: () => import('./views/auth-container/auth-container.module').then((m) => m.AuthContainerModule),
	},
	{
		path: 'join',
		loadChildren: () => import('./views/auth-container/auth-container.module').then((m) => m.AuthContainerModule),
	},
	{
		path: 'subscription',
		canLoad: [MobileGuard, AuthGuard],
		data: {role: [UserType.USER], fallbackUrl: '/auth/login'},
		loadChildren: () => import('./views/subscription/subscription.module').then((m) => m.SubscriptionModule),
	},
	{
		path: 'education',
		canLoad: [MobileGuard, AuthGuard],
		data: {role: [UserType.USER], fallbackUrl: '/auth/login'},
		loadChildren: () => import('./views/education/education.module').then((m) => m.EducationModule),
	},
	{
		path: 'waiting-list',
		loadChildren: () => import('./views/waiting-list/waiting-list.module').then((m) => m.WaitingListModule),
	},
	{
		path: 'admin',
		loadChildren: () => import('./views/admin/admin.module').then((m) => m.AdminModule),
	},
	{
		path: 'profile-editing',
		canLoad: [MobileGuard, AuthGuard],
		data: {role: [UserType.USER], fallbackUrl: '/auth/login'},
		loadChildren: () =>
			import('./views/profile-editing/profile-editing.module').then((m) => m.ProfileEditingModule),
	},
	{
		path: 'account',
		canLoad: [MobileGuard, AuthGuard],
		data: {role: [UserType.USER], fallbackUrl: '/auth/login'},
		loadChildren: () =>
			import('./views/account-statements/account-statements.module').then((m) => m.AccountStatementsModule),
	},
	{
		path: 'agreements/:section',
		loadChildren: () => import('./views/terms-policy/terms-policy.module').then((m) => m.TermsPolicyModule),
	},
	{
		path: 'wallet',
		canLoad: [MobileGuard, AuthGuard],
		data: {role: [UserType.USER], fallbackUrl: '/auth/login'},
		loadChildren: () => import('./views/wallet/wallet.module').then((m) => m.WalletModule),
	},
	{
		path: 'invest',
		loadChildren: () => import('./views/invest/invest.module').then((m) => m.InvestModule),
	},
	{
		path: 'encerramento',
		loadChildren: () => import('./views/encerramento/encerramento.module').then((m) => m.EncerramentoModule),
	},
	{
		path: '',
		pathMatch: 'full',
		redirectTo: '/auth/login',
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			paramsInheritanceStrategy: 'always',
			anchorScrolling: 'enabled',
			scrollPositionRestoration: 'enabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
