import {QuizAnswer} from '@savvy/models/store';

export namespace Courses {
	export class Load {
		static readonly type = '[Courses] Load';
	}

	export class LoadDetails {
		static readonly type = '[Courses] Load Details';

		constructor(public slug: string) {}
	}

	export class CreateReview {
		static readonly type = '[Courses] Create Review';

		constructor(public slug: string, public rating: number, public feedback: string) {}
	}

	export class SaveQuiz {
		static readonly type = '[Courses] Save Quiz';

		constructor(public slug: string, public answers: QuizAnswer[]) {}
	}
}
