import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';

import {AssetsModule} from '@savvy/components/assets/assets.module';
import {SearchComponent} from './search.component';


@NgModule({
  declarations: [SearchComponent],
  imports: [
    CommonModule,
    AssetsModule,
    //lib
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
  ],
  exports: [SearchComponent],
})
export class SearchModule { }
