import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'cpf',
})
export class CpfPipe implements PipeTransform {
	transform(value: string): string {
		value = value.replace(/[.]/g, '');
		value = value.replace(/[-]/g, '');
		return value.length > 3
			? `${value.substr(0, 3)}.${value.substr(3, 3)}.${value.substr(6, 3)}-${value.substr(9)}`
			: value.length === 14
			? `${value.substr(0, 2)}.${value.substr(2, 3)}.${value.substr(5, 3)}/${value.substr(8, 4)}-${value.substr(
					12,
					2,
			  )}`
			: '';
	}
}
