export * from './courses';
export * from './notifications';
export * from './portfolio';
export * from './subscription';
export * from './user';
export * from './activity';
export * from './transactions';

export interface CommonError {
	error: string;
}

export interface Locale {
	name: string;
	code: string;
}
