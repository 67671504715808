import {animate, style, transition, trigger} from '@angular/animations';
import {Component} from '@angular/core';

import {TooltipMessage, TooltipMessageType} from '@savvy/models/tooltip.model';

@Component({
	selector: 'savvy-tooltip-messages',
	templateUrl: './tooltip-messages.component.html',
	styleUrls: ['./tooltip-messages.component.scss'],
	animations: [
		trigger('tooltipsTrigger', [
			transition(':enter', [
				style({transform: 'translateX(450px)', opacity: '.2'}),
				animate('300ms cubic-bezier(.5,.3,.4, 1)', style({transform: 'translateX(0)', opacity: '1'})),
			]),
			transition(':leave', [animate('300ms cubic-bezier(.5,.3,.4, 1)', style({transform: 'translateX(450px)', opacity: '.2'}))]),
		]),
	],
})
export class TooltipMessagesComponent {
	tooltips: TooltipMessage[] = [];

	public showMessage(message: TooltipMessage): void {
		if (this.notExist(message)) {
			this.tooltips.push(message);
		}
	}

	public deleteMessage(type: TooltipMessageType): void {
		this.tooltips = this.tooltips.filter((element) => element.type !== type);
	}

	notExist(message: TooltipMessage): boolean {
		return !this.tooltips.find((element) => element.message === message.message);
	}
}
