import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';

import {
	DisplayPieSlice,
	EntryType,
	GeneralisedEntry,
	Group,
	GroupLoners,
	GroupsResponse,
	Instrument,
	InstrumentDetails,
	ListEntry,
	Loner,
	UpdatePieRequest,
} from '@savvy/models/store';
import {PriceSubscribeType} from '@savvy/models/store/stream';
import {StreamState} from '@savvy/store/state/stream.state';

@Injectable({providedIn: 'root'})
export class CoreService {
	constructor(private http: HttpClient, private store: Store) {}

	// TODO: Broken, left to keep typing, remove when migration to NGXS is completed
	getPortfolio() {
		return combineLatest([
			this.http.get<GroupsResponse>('/api/core/portfolio'),
			this.http.get<GroupsResponse>('/api/core/watchlist'),
		]).pipe(map(([portfolio, watchlist]) => ({portfolio, watchlist})));
	}

	getNewPortfolio() {
		return combineLatest([
			this.http.get<{entries: ListEntry[]}>('/api/core/portfolio'),
			this.http.get<{entries: ListEntry[]}>('/api/core/watchlist'),
		]).pipe(map(([{entries: portfolio}, {entries: watchlist}]) => ({portfolio, watchlist})));
	}

	getInstruments() {
		return this.http.get<Instrument[]>('/api/securities/instruments?status=ACTIVE');
	}

	getInstrumentDetails(instrumentId: string) {
		return this.http.get<InstrumentDetails>(`/api/securities/instruments/${instrumentId}`);
	}

	updateGroup(id: string, data: UpdatePieRequest) {
		return this.http.post<void>('/api/core/updateGroupMeta', {
			groupId: id,
			meta: data,
		});
	}

	deleteGroup(id: string) {
		return this.http.post<void>('/api/core/deleteGroup', {
			groupId: id,
		});
	}

	createPie(assets: Omit<Loner, 'id'>[]) {
		return this.http.post<Group>('/api/core/createGroup', {assets});
	}

	manageLoners(data: {toCreate: string[]; toDelete: string[]}) {
		return this.http.post<GroupLoners>('/api/core/manageLoners', data);
	}

	mapEntryForDisplay(entry: ListEntry): DisplayPieSlice {
		return {
			entry: {
				type: entry.type,
				...entry.data,
			} as GeneralisedEntry,
			colour: null,
			price$: this.store.select(StreamState.priceFor(...this.mapEntryToPriceEvent(entry))),
			link: {
				path:
					entry.type === 1 ? `/invest/asset/${entry.data.symbol}` : `/invest/pie/portfolio/${entry.data.id}`,
			},
		};
	}

	mapEntryToPriceEvent(entry: ListEntry): [PriceSubscribeType, string] {
		const type = {
			[EntryType.Group]: PriceSubscribeType.Pie,
			[EntryType.Loner]: PriceSubscribeType.Stock,
		}[entry.type];
		return [type, entry.type === EntryType.Loner ? entry.data.symbol : entry.data.id];
	}
}
