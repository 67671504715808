import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {first, map, tap} from 'rxjs/operators';

import {
	CreateSubscriptionRequestBody,
	CreateSubscriptionResponse,
	Subscription,
} from '@savvy/models/store/subscription';

declare let __savvy__stripe: Observable<void>;

@Injectable({providedIn: 'root'})
export class SubscriptionService {
	public readonly PUB_KEY =
		'pk_test_51GsXduHbcCkAzV5jT4R48dmGdjy3gxM2SgCqH0nsDPki5ZdN99E8gKmkhcHGHEsRYKVeDTH4TwDMn6gcrMhs2WIc00BRsEHxDr';

	public stripe: stripe.Stripe;

	constructor(private http: HttpClient) {}

	loadStripe() {
		if (!('__savvy__stripe' in window)) {
			Object.defineProperty(window, '__savvy__stripe', {value: null, writable: true});
		}
		if (__savvy__stripe) {
			return __savvy__stripe;
		}
		return (__savvy__stripe = new Observable((subscriber) => {
			if (window.Stripe) {
				return subscriber.next();
			}
			const script = document.createElement('script');
			script.src = 'https://js.stripe.com/v3';
			(document.head || document.body).appendChild(script);
			script.addEventListener('load', () => subscriber.next());
		}).pipe(
			first<void>(),
			tap(() => (__savvy__stripe = null) as null),
			tap(() => (this.stripe = Stripe(this.PUB_KEY))),
		));
	}

	load() {
		return this.http.get<{subscriptions: Subscription[]}>('/api/subscription').pipe(
			map(({subscriptions}) =>
				subscriptions.map((item) => {
					item.price.sort((a, b) => a.interval - b.interval);
					return {...item, link: item.name.toLowerCase().replace(/[^A-z\d]/g, '-')};
				}),
			),
		);
	}

	create(config: CreateSubscriptionRequestBody) {
		return this.http.post<CreateSubscriptionResponse>('/api/subscription', config);
	}

	retry(config: CreateSubscriptionRequestBody) {
		return this.http.patch<CreateSubscriptionResponse['latest_invoice']>('/api/subscription', config);
	}

	delete() {
		return this.http.delete<void>('/api/subscription');
	}

	async onCustomerActionRequired({
		subscription,
		paymentMethodId,
		invoice,
		isRetry,
	}: {
		subscription?: CreateSubscriptionResponse;
		paymentMethodId: string;
		invoice?: CreateSubscriptionResponse['latest_invoice'];
		isRetry?: boolean;
	}) {
		if (subscription?.status === 'active') {
			return {subscription, paymentMethodId};
		}
		const paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;
		if (
			paymentIntent.status === 'requires_action' ||
			(isRetry && paymentIntent.status === 'requires_payment_method')
		) {
			const res = await this.stripe.confirmCardPayment(paymentIntent.client_secret, {
				payment_method: paymentMethodId,
			});
			if (res.error) {
				throw res;
			}
			if (res.paymentIntent.status === 'succeeded') {
				return {
					subscription,
					paymentMethodId,
				};
			}
		}
		return {subscription, paymentMethodId};
	}

	onPaymentMethodRequired({
		subscription,
		paymentMethodId,
	}: {
		subscription: CreateSubscriptionResponse;
		paymentMethodId: string;
	}) {
		if (subscription.status === 'active') {
			return {subscription, paymentMethodId};
		} else if (subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
			localStorage.setItem('stripe__latestInvoiceId', subscription.latest_invoice.id);
			localStorage.setItem(
				'stripe__latestInvoicePaymentIntentStatus',
				subscription.latest_invoice.payment_intent.status,
			);
			// eslint-disable-next-line no-throw-literal
			throw {error: {message: 'Your card was declined.'}};
		}
		return {subscription, paymentMethodId};
	}
}
