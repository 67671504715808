import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'indexToChar'
})
export class IndexToCharPipe implements PipeTransform {

	transform(value: number): string {
		return String.fromCharCode(value + 65);
	}

}
