import {HttpErrorResponse} from '@angular/common/http';
import {ErrorHandler, Injectable} from '@angular/core';

import {MatSnackBar} from '@angular/material/snack-bar';

declare let __getLog: () => string[];
declare let __saveLog: () => void;

@Injectable({providedIn: 'root'})
export class GlobalErrorHandler implements ErrorHandler {
	static events: string[];

	constructor(private snackBar: MatSnackBar) {
		if (GlobalErrorHandler.events) {
			return;
		}
		GlobalErrorHandler.events = [];

		document.addEventListener('click', (event) =>
			GlobalErrorHandler.events.push(
				`[click: ${new Date().toISOString()}] ${this.buildSelector(event.target as HTMLElement)}`,
			),
		);
		['log', 'info', 'warn', 'debug', 'error'].forEach((level: keyof Console) => {
			// eslint-disable-next-line no-console
			const origin = console[level] as (...data: any[]) => void;
			// eslint-disable-next-line no-console
			console[level] = ((...data: any[]) => {
				try {
					GlobalErrorHandler.events.push(
						`[log(${level}): ${new Date().toISOString()}] ${JSON.stringify(data, null, '\t')}`,
					);
				} catch (error) {
					GlobalErrorHandler.events.push(
						`[log(${level}): ${new Date().toISOString()}] <JSON.stringify failed> ${data}`,
					);
				}
				return origin(...data);
			}) as any;
		});

		if (!('__getLog' in window)) {
			Object.defineProperty(window, '__getLog', {value: null, writable: true});
		}

		if (!('__saveLog' in window)) {
			Object.defineProperty(window, '__saveLog', {value: null, writable: true});
		}
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		__getLog = () => GlobalErrorHandler.events;
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		__saveLog = () => this.saveLog();
	}

	static log(tag: string, ...data: any[]) {
		GlobalErrorHandler.events.push(
			`[log(${tag}): ${new Date().toISOString()}] ${JSON.stringify(data, null, '\t')}`,
		);
	}

	buildSelector(element: HTMLElement | SVGElement) {
		const path = [];
		let node = element;
		while (node) {
			const id = node.dataset['test-id'] || node.id;
			if (id) {
				path.push(`#${id}`);
			}
			node = node.parentElement;
		}
		return path.reverse().join(' > ');
	}

	saveLog() {
		const data = new Blob([GlobalErrorHandler.events.join('\n')], {type: 'text/plain;charset=utf-8'});
		const url = URL.createObjectURL(data);

		const a = document.createElement('a');
		a.href = url;
		a.download = `savvy-web-${new Date().toISOString()}.log`;
		a.click();
		URL.revokeObjectURL(url);
	}

	showError(error: any, knownError = false) {
		const snack = this.snackBar.open(error, 'Save log', {
			panelClass: knownError ? '' : 'error',
			duration: knownError ? 3000 : 60000,
		});
		console.error(error);
		snack.afterDismissed().subscribe(({dismissedByAction}) => dismissedByAction && this.saveLog());
	}

	async handleError(error: Error) {
		if (error instanceof HttpErrorResponse && error.status === 401) {
			return;
		}

		/* eslint-disable @typescript-eslint/dot-notation */
		/*if (error['parsed']) {
			this.showError(error['parsed'], true);
		} else {
			this.showError((error as HttpErrorResponse).error?.message || error.message || error);
		}*/
		/* eslint-enable @typescript-eslint/dot-notation */
	}
}
