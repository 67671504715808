import {DataSource} from '@angular/cdk/table';
import {Store} from '@ngxs/store';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {debounceTime, map, startWith, tap} from 'rxjs/operators';

import {pick} from '@savvy/helpers/pick';
import {DisplayAsset, Instrument} from '@savvy/models/store';
import {PriceSubscribeType} from '@savvy/models/store/stream';
import {PortfolioService} from '@savvy/services/portfolio.service';
import {UtilService} from '@savvy/services/util.service';
import {Core} from '@savvy/store/actions/core.actions';
import {Stream} from '@savvy/store/actions/stream.actions';
import {CoreState} from '@savvy/store/state/core.state';
import {StreamState} from '@savvy/store/state/stream.state';

export class AssetsDataSource extends DataSource<DisplayAsset> {
	public readonly sort$ = new BehaviorSubject<keyof DisplayAsset>('name');
	public readonly direction$ = new BehaviorSubject<'asc' | 'desc' | ''>('asc');
	public readonly page$ = new BehaviorSubject<number>(0);
	public readonly search$ = new BehaviorSubject<string>('');
	public readonly category$ = new BehaviorSubject<string>('');

	public readonly length$ = new BehaviorSubject<number>(0);

	// TODO: Use infinite scrolling
	public readonly pageSize = 10000;

	private data$ = new BehaviorSubject<Instrument[]>([]);

	constructor(
		data: Instrument[],
		private store: Store,
		private portfolioService: PortfolioService,
		private utilService: UtilService,
	) {
		super();
		this.data$.next(data);
	}

	public set data(value: Instrument[]) {
		this.data$.next(value);
	}

	connect(): Observable<DisplayAsset[]> {
		return combineLatest([
			this.data$,
			this.page$,
			this.search$.pipe(debounceTime(200)),
			this.category$,
			this.sort$,
			this.direction$,
		]).pipe(
			map(([instruments, page, search, category]) => {
				const direction = this.direction$.value === 'desc' ? -1 : 1;
				if (this.sort$.value === 'name') {
					instruments = instruments
						.slice()
						.sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) * direction : 0));
				}

				const caseSearch = search.toLowerCase().trim();
				let filtered = instruments;
				if (category) {
					filtered = filtered.filter(({categories}) => categories.map(({id}) => id).includes(category));
				}
				if (caseSearch) {
					filtered = filtered.filter(({name, symbol}) => (name + symbol).toLowerCase().includes(caseSearch));
				}

				this.length$.next(filtered.length);

				return filtered.slice(page * this.pageSize, page * this.pageSize + this.pageSize);
			}),
			startWith([]),
			map((instruments) =>
				instruments.map((instrument) => ({
					...pick(instrument, 'id', 'name', 'symbol'),
					image: this.utilService.buildAssetImageURL(instrument.symbol),
					details$: this.store.select(CoreState.instrument(instrument.symbol)).pipe(
						tap({
							subscribe: () => {
								this.store.dispatch(new Core.GetInstrumentDetails(instrument.symbol));
							},
						}),
						map((details) => ({
							peRatio: details?.fundamentalDataModel?.peRatio,
							yield: details?.fundamentalDataModel?.dividendYield / 100,
						})),
					),

					price$: this.store.select(StreamState.priceFor(PriceSubscribeType.Stock, instrument.symbol)).pipe(
						debounceTime(100),
						tap({
							subscribe: () => {
								this.store.dispatch(
									new Stream.Subscribe.Price(PriceSubscribeType.Stock, instrument.symbol),
								);
							},
							unsubscribe: () => {
								this.store.dispatch(
									new Stream.Unsubscribe.Price(PriceSubscribeType.Stock, instrument.symbol),
								);
							},
						}),
					),
					isInWatchlist$: this.store
						.select(CoreState.watchlistLoners)
						.pipe(map((symbols) => symbols.includes(instrument.symbol))),
					isInPortfolio$: this.store
						.select(CoreState.portfolioLoners)
						.pipe(map((symbols) => symbols.includes(instrument.symbol))),
				})),
			),
		);
	}

	disconnect() {
		this.sort$.complete();
		this.direction$.complete();
	}
}
