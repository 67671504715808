<savvy-assets
	mode="select"
	title="Add Stocks"
	[selected]="selectedAssets"
	(symbolClick)="toggleAsset($event)"
></savvy-assets>

<div class="actions">
	<button (click)="close()" mat-stroked-button color="primary">Cancel</button>
	<button mat-raised-button color="primary" (click)="save()">Save</button>
</div>
