import {Pipe, PipeTransform} from '@angular/core';

export const CompactFormatter = new Intl.NumberFormat('en-US', {
	notation: 'compact',
	compactDisplay: 'short',
});

@Pipe({
	name: 'compactDecimal',
})
export class CompactDecimalPipe implements PipeTransform {
	transform(value: number): string {
		return CompactFormatter.format(value);
	}
}
