<mat-card class="savvy-mat-card-no-bottom mat-elevation-z0">
	<mat-card-title>
		<div>
			<span>{{ title }}</span>
		</div>
		<div class="search">
			<mat-form-field>
				<mat-label>Search for stocks, SMAs, ETFs…</mat-label>
				<input matInput type="search" (input)="onSearch($event.target.value)" />
				<img matSuffix src="assets/search.svg" alt="" />
			</mat-form-field>
		</div>
	</mat-card-title>
	<nav class="stocks">
		<span [ngClass]="selectedCategories ? 'unavailable' : 'active'" (click)="selectCategory(null)">All</span>
		<span *ngFor="let categories of (categories$ | async)"
			 [ngClass]="selectedCategories === categories.id ? 'active' : 'unavailable'"
			 (click)="selectCategory(categories.id)">{{categories.name}}</span>
	</nav>

	<mat-card-content class="savvy-scroll">
		<savvy-loader [enabled]="(dataSource.length$ | async) === 0"></savvy-loader>

		<savvy-lazy-table
			[attr.mode]="mode"
			[dataSource]="dataSource"
			[displayedColumns]="displayedColumns"
			[height]="300"
			(rowClick)="toggleAsset($event.symbol)"
		>
			<div *savvyLazyColumn="let element; name: 'Name'; key: 'name'" savvyColumn class="col-name">
				<img savvyPreloadImage [src]="element.image" />

				<span class="symbol">{{ element.symbol }}</span>
				<span title="{{ element.name }}">{{ element.name }}</span>
			</div>

			<div *savvyLazyColumn="let element; name: 'Price'; key: 'price'" savvyColumn>
				<span *ngIf="element.price$ | async as price">
					{{ price.lastTrade | currency: 'USD':'symbol':'1.2-2' }}
				</span>
			</div>

			<div *savvyLazyColumn="let element; name: 'Div. Yield'; key: 'yield'" savvyColumn>
				<span *ngIf="element.details$ | async as details">
					{{ (details.yield | percent: '1.2-2') || '-' }}
				</span>
			</div>

			<div *savvyLazyColumn="let element; name: 'Price History'; key: 'change'" savvyColumn>
				<ng-container *ngIf="element.price$ | async as price">
					<span savvyGainNoArrow [savvyGain]="price.dailyChangePct">
						{{ price.dailyChange | currency: 'USD':'symbol':'1.2-2' }}
					</span>
					<span [savvyGain]="price.dailyChangePct" savvyGainParenthesis>
						{{ price.dailyChangePct | percent: '1.2-2' }}
					</span>
				</ng-container>
			</div>

			<div *savvyLazyColumn="let element; name: 'P/E ratio'; key: 'peRatio'" savvyColumn>
				<span *ngIf="element.details$ | async as details">
					{{ (details.peRatio | percent: '1.2-2') || '-' }}
				</span>
			</div>

			<div *savvyLazyColumn="let element; name: ''; key: 'status'" savvyColumn>
				<img *ngIf="element.isInWatchlist$ | async" class="icon" src="assets/watchlist.svg" alt="" />
				<img *ngIf="element.isInPortfolio$ | async" class="icon" src="assets/pie.svg" alt="" />
			</div>

			<div *savvyLazyColumn="let element; name: ''; key: 'checkmark'" class="checkmark" savvyColumn>
				<savvy-checkmark
					(click)="toggleAsset(element.symbol)"
					[value]="selected.has(element.symbol)"
				></savvy-checkmark>
			</div>
		</savvy-lazy-table>
	</mat-card-content>
</mat-card>
