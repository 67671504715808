import {NgModule} from '@angular/core';

import {AbsolutePipe} from './absolute.pipe';
import {CompactDecimalPipe} from './compact-decimal.pipe';
import {CpfPipe} from './cpf.pipe';
import { DatePipe } from './date.pipe';
import {IndexToCharPipe} from './indexToChar.pipe';
import {JoinPipe} from './join.pipe';
import {MapPipe} from './map.pipe';
import {MinToDatePipe} from './minToDate.pipe';
import {PhonePipe} from './phone.pipe';
import {SafePipe} from './safe.pipe';
import {StartsWithPipe} from './startsWith.pipe';
import {TimePipe} from './time.pipe';
import {TruncatePipe} from './truncate.pipe';
import {ZipCodePipe} from './zipCode.pipe';

@NgModule({
	declarations: [
		//
		TruncatePipe,
		MinToDatePipe,
		IndexToCharPipe,
		MapPipe,
		JoinPipe,
		AbsolutePipe,
		CompactDecimalPipe,
		StartsWithPipe,
		CpfPipe,
		TimePipe,
		SafePipe,
		PhonePipe,
		ZipCodePipe,
		DatePipe,
	],
	exports: [
		//
		TruncatePipe,
		MinToDatePipe,
		IndexToCharPipe,
		MapPipe,
		JoinPipe,
		AbsolutePipe,
		CompactDecimalPipe,
		StartsWithPipe,
		CpfPipe,
		TimePipe,
		SafePipe,
		PhonePipe,
		ZipCodePipe,
		DatePipe
	],
})
export class PipesModule {}
