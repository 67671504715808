import {ScrollingModule} from '@angular/cdk/scrolling';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {PipesModule} from '@savvy/pipes/pipes.module';

import {NotificationsComponent} from './notifications.component';

@NgModule({
	declarations: [NotificationsComponent],
	exports: [NotificationsComponent],
	imports: [CommonModule, MatIconModule, MatButtonModule, PipesModule, ScrollingModule],
})
export class NotificationsModule {}
