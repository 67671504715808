import firebase from 'firebase/compat';

export interface QuizAnswer {
	question: string | number;
	answer: string | number;
}

export namespace FirebaseCourse {
	export interface Course {
		title: string;
		slug: string;
		description: string;
		image: string;
		overview: string;
		isPublished: boolean;
		syllabus: string[];
		videos: CourseVideo[];
		questions: CourseQuestion[];
		duration: number;
		score: number;
	}

	export interface CourseVideo {
		uid: string;
		title: string;
		duration: number;
		restricted: boolean;
	}

	export interface CourseQuestion {
		title: string;
		answers: string[];
	}

	export interface Review {
		author: string;
		rating: number;
		feedback: string;
		createdAt: firebase.firestore.Timestamp;
	}
}
