import {Component} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';

import {Auth} from '@savvy/store/actions/auth.actions';
import {AuthState} from '@savvy/store/state/auth.state';

@Component({
	selector: 'savvy-invite-code',
	templateUrl: './invite-code.component.html',
	styleUrls: ['./invite-code.component.scss'],
})
export class InviteCodeComponent {
	@Select(AuthState.error())
	error$: Observable<string>;

	form = this.formBuilder.group({
		code: ['', Validators.required],
	});

	constructor(private formBuilder: UntypedFormBuilder, private store: Store, private dialogRef: MatDialogRef<any>) {}

	onSubmit() {
		this.store
			.dispatch(new Auth.CheckInviteCode(this.form.controls.code.value))
			.subscribe(() => this.dialogRef.close());
	}
}
