import {OverlayModule} from '@angular/cdk/overlay';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {ErrorHandler, NgModule} from '@angular/core';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {AngularFirePerformanceModule, PerformanceMonitoringService} from '@angular/fire/compat/performance';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {
	MatFormFieldDefaultOptions,
	MatFormFieldModule,
	MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig} from '@angular/material/snack-bar';
import {MatToolbarModule} from '@angular/material/toolbar';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsRouterPluginModule} from '@ngxs/router-plugin';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import {NgxsModule} from '@ngxs/store';
import {ConnectionServiceModule} from 'ng-connection-service';
import {TooltipModule} from '@savvy/directives/tooltip/tooltip.module';

import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {AlertComponent} from './components/alert/alert.component';
import {InviteCodeComponent} from './components/invite-code/invite-code.component';
import {NotificationsModule} from './components/notifications/notifications.module';
import {QrTooltipModule} from './components/qr-tooltip/qr-tooltip.module';
import {SearchModule} from './components/search/search.module';
import {StockModule} from './components/stock/stock.module';
import {TooltipMessagesComponent} from './components/tooltip-messages/tooltip-messages.component';
import {WaveModule} from './components/wave/wave.module';
import {AccountExistComponent} from './modal/accountExists/accountExists';
import {PipesModule} from './pipes/pipes.module';

import {AuthInterceptor} from './services/auth.interceptor';
import {GlobalErrorHandler} from './services/error.handler';
import {states, statesToPreserve} from './store/state';

const matFormFieldDefaultOptions: MatFormFieldDefaultOptions = {
	appearance: 'outline',
};

const matSnackBarDefaultOptions: MatSnackBarConfig = {
	duration: 3000,
};

@NgModule({
	declarations: [AppComponent, AlertComponent, TooltipMessagesComponent, InviteCodeComponent, AccountExistComponent],
	imports: [
		BrowserModule,
		MatSelectModule,

		NgxsModule.forRoot(states, {
			developmentMode: !environment.production,
			selectorOptions: {
				suppressErrors: false,
				injectContainerState: false,
			},
		}),
		NgxsRouterPluginModule.forRoot(),
		NgxsStoragePluginModule.forRoot({
			key: statesToPreserve,
		}),
		NgxsReduxDevtoolsPluginModule.forRoot(),

		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,

		// local
		NotificationsModule,
		SearchModule,
		OverlayModule,
		PipesModule,
		WaveModule,
		StockModule,
		TooltipModule,
		QrTooltipModule,

		// lib
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		MatButtonModule,
		MatSidenavModule,
		MatListModule,
		MatToolbarModule,
		MatDialogModule,
		MatSnackBarModule,
		MatBadgeModule,
		MatDatepickerModule,
		MatNativeDateModule,

		AngularFireModule.initializeApp(environment.firebase),
		AngularFirestoreModule,
		AngularFirePerformanceModule,
		ConnectionServiceModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: matFormFieldDefaultOptions,
		},
		{
			provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
			useValue: matSnackBarDefaultOptions,
		},
		{
			provide: ErrorHandler,
			useClass: GlobalErrorHandler,
		},
		{provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
		PerformanceMonitoringService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
